import {MEDIA_TYPE} from "./constants";
import type {ContentType, ContentTypeLight} from "@/types/contentType";
import {RecommendedListType} from "@/types/listType";

export function isBrowser() {
    return typeof window !== 'undefined';
}

export function isDevelopment() {
    return process.env.NODE_ENV === 'development';
}

export function convertQueryObjectToQueryString(object: any) {
    const params = new URLSearchParams(object);
    return decodeURIComponent(params.toString());
}

export function turnFilterQueryToApiObjects(query?: any) {
    if (!query && isBrowser()) {
        query = require('next/client').router.query;
    }
    return {
        streams: query.streams ? query.streams.split(',') : undefined,
        genres: query.genres ? query.genres.split(',') : undefined,
        country: query.country ? query.country.split(',') : undefined,
        sortBy: query.sortBy ? query.sortBy : 'Popularity',
        mediaType: query.mediaType ? MEDIA_TYPE[query.mediaType as keyof typeof MEDIA_TYPE] : 'icerikler'
    };
}


type RecentViewType =
    {
        type: 'content',
        data: ContentType | ContentTypeLight
    }

export function addDataToRecentView({data, type}: RecentViewType) {
    const recentViews = getRecentViews();
    if (!data || !type) return recentViews;

    const index = recentViews.findIndex(i => i.data.id === data.id);

    // ayni icerik geldiyse listenin basina al
    if (index !== -1) {
        recentViews.splice(index, 1);
    }
    recentViews.unshift({data, type});
    if (recentViews.length > 20) {
        recentViews.pop();
    }
    localStorage.setItem('recent_views', JSON.stringify(recentViews));

    return recentViews;
}

export function getRecentViews(excludeId?: string): RecentViewType[] {
    if (!isBrowser()) return [];
    const recentViews = localStorage.getItem('recent_views');
    return recentViews ?
        (JSON.parse(recentViews) as RecentViewType[]).filter(i => i.data.id !== excludeId)
        :
        [];
}

export function clearRecentViews(){
    localStorage.removeItem("recent_views");
}

const RECENT_SEARCHES_KEY = "recent_searches";
export function addDataToRecentSearch(query:string):RecentSearchItem[] {
    const recentSearches = getRecentSearches();
    if(!query) return recentSearches;

    const index = recentSearches.findIndex(item => item.toLowerCase() == query.toLowerCase());

    // Eğer aynı arama daha önce yapılmışsa listenin başına al
    if(index !== -1)
        recentSearches.splice(index, 1);

    recentSearches.unshift(query);

    if(recentSearches.length > 10)
        recentSearches.pop();

    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches));

    return recentSearches;
}

type RecentSearchItem = string;
export function getRecentSearches():RecentSearchItem[] {
    if(!isBrowser()) return [];

    const recentSearches = localStorage.getItem(RECENT_SEARCHES_KEY);
    return recentSearches ? (JSON.parse(recentSearches) as RecentSearchItem[]) : [];
}

export function removeRecentSearchItem(itemToRemove:RecentSearchItem):RecentSearchItem[]{
    const recentSearches = getRecentSearches();
    const filteredSearches = recentSearches.filter(item => item !== itemToRemove);

    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(filteredSearches));

    return filteredSearches;
}

export function clearRecentSearches() {
    localStorage.removeItem(RECENT_SEARCHES_KEY);
}
