import {endPoints} from "@/helper/endPoints";
import {api, type ApiProps} from "@/helper/apiHelper";
import type {UserType} from "@/types/core";
import type {AuthApiResponse} from "@/types/apiType";

/** AUTH APIS **/
export const login = async (data: any, ctx?: ApiProps["ctx"]) => {
    return await api<AuthApiResponse>({url: endPoints.auth.login, data, ctx});
};
export const register = async (data: any, ctx?: ApiProps["ctx"]) => {
    return await api<AuthApiResponse>({url: endPoints.auth.register, data, ctx});
};
export const getMyUser = async (ctx?: ApiProps["ctx"]) => {
    return await api<UserType>({url: endPoints.auth.getMyUser, method: "GET", ctx});
};
export const refresh = async (refreshToken: string, ctx?: ApiProps["ctx"]) => {
    return await api<AuthApiResponse>({
        url: endPoints.auth.refresh,
        method: "GET",
        ctx,
        additionalHeaders: {
            "X-Refresh-Token": refreshToken,
        },
    });
};
export const updateProfile = async (data: any, ctx?: ApiProps["ctx"]) => {
    return await api<UserType>({url: endPoints.auth.updateProfile, data, ctx});
};
export const checkUserName = async (userName: string, ctx?: ApiProps["ctx"]) => {
    return await api<UserType>({url: `${endPoints.auth.checkUserName}/${userName}`, method: "GET", ctx});
};
export const emailVerify = async (token: string, ctx?: ApiProps["ctx"]) => {
    return await api<null>({url: `${endPoints.auth.emailVerify}?token=${token}`, method: "GET", ctx});
};
export const sendEmailVerification = async (ctx?: ApiProps["ctx"]) => {
    return await api<null>({url: endPoints.auth.sendEmailVerification, method: "GET", ctx});
};
export const forgotPassword = async (userName: string, ctx?: ApiProps["ctx"]) => {
    return await api<null>({url: `${endPoints.auth.forgotPassword}/${userName}`, method: "GET", ctx});
};
export const forgotPasswordVerify = async (data: { token: string, newPassword: string }, ctx?: ApiProps["ctx"]) => {
    return await api<null>({url: endPoints.auth.forgotPasswordVerify, data, ctx});
};
export const isUserExitsByProvider = async (data: { token: string, provider: 'google' | 'facebook' }, ctx?: ApiProps["ctx"]) => {
    return await api<{accessToken: string, userExists: boolean}>({url: endPoints.auth.isUserExits, data, ctx});
};

