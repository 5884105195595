import React, {memo} from "react";
import Nouislider from "nouislider-react";
import {NUMBER_LIST_DEFAULT} from "@/helper/constants";
import {useDispatch, useSelector} from "react-redux";
import Image from "next/image";
import * as Popover from "@radix-ui/react-popover";
import Button from "@/components/form/Button";


type NumItemType = typeof NUMBER_LIST_DEFAULT['year'];
const numberArr: NumItemType[] = Object.values(NUMBER_LIST_DEFAULT);

function NumbersFilterBox({withoutPopup}: {withoutPopup?: boolean}) {
  const numbers = useSelector((state: any) => state.generalReducer.filters.numbers);
  const dispatch = useDispatch();


  const [labels, setLabels] = React.useState(numbers);
  const labelsRef = React.useRef(labels);
  labelsRef.current = labels;

  // @ts-ignore
  const onSlide = (item: NumItemType) => (render, handle, value) => {
    // ref lazim cunku eski value'ya setleniyor
    const cloneLabels = {...labelsRef.current};
    cloneLabels[item.key] = {start: value[0], end: value[1]};
    setLabels(cloneLabels);
  }

  // @ts-ignore
  const onChange = (item: NumItemType) => (render, handle, value) => {
    dispatch({
      type: 'SET_FILTER_NUMBERS',
      payload: {key: item.key, value: {start: value[0], end: value[1]}}
    });
  }

  if (withoutPopup) return renderInside();

  return (
    <Popover.Root>
      <Popover.Trigger asChild type='button'>
        <Button buttonType='black'>
          Yıl ve Puan
          <Image src="/icons/arrowDown.svg" alt="arrowdown" width={8} height={8}/>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="PopoverContent" sideOffset={5} style={{width: 'unset'}}>
          <div className='number-filter-box'>
            {renderInside()}
          </div>
          <Popover.Arrow className="PopoverArrow"/>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )

  function renderInside() {
    return (
      <>
        {numberArr.map((item, index) =>
          <div className='number-filter-box-item' key={item.key}>
            <p className='number-filter-title'>{item.title}</p>
            <div className='d-flex align-items-center w-100'>
              <div className='numbers-value'>{labels[item.key].start}</div>
              <Nouislider
                className="slim-slider"
                start={[numbers[item.key].start, numbers[item.key].end]}
                range={{min: item.start, max: item.end}}
                step={item.step}
                onChange={onChange(item)}
                onSlide={onSlide(item)}
                connect={true}
              />
              <div className='numbers-value text-end'>{labels[item.key].end}</div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default memo(NumbersFilterBox);
