import * as Check from "@radix-ui/react-checkbox";
import {forwardRef, ReactNode} from "react";
import type {ForwardedRef} from 'react';
import type {CheckboxProps} from "@radix-ui/react-checkbox";
import {CheckboxCheckIcon} from "@/icons/index";

export interface CCheckboxProps extends CheckboxProps {
    label?: string | ReactNode;
    error?: string;
}

function Checkbox(props: CCheckboxProps, ref?: ForwardedRef<HTMLButtonElement>) {
    const {
        className,
        label,
        defaultChecked = false,
        error,
        ...restProps
    } = props;
    return (
        <div className={className ? className : ""}>
            <div
                className="checkbox-wrapper"
                style={{display: "flex", alignItems: "center"}}
            >
                <Check.Root
                    ref={ref}
                    className="CheckboxRoot"
                    defaultChecked={defaultChecked}
                    {...restProps}
                >
                    <Check.Indicator className="CheckboxIndicator">
                        <CheckboxCheckIcon/>
                    </Check.Indicator>
                </Check.Root>
                {!!label &&
                    <label className="Label" htmlFor={restProps.id}>
                        {label}
                    </label>
                }
            </div>
            {error && <div className="checkbox-error">{error}</div>}
        </div>
    );
}

export default forwardRef(Checkbox);
