import { useState, useEffect } from "react";

const useBreakpoint = (width = 1024) => {
  const [breaking, setBreaking] = useState(undefined);
  const resize = () => {
    if (window.innerWidth < width) setBreaking(true);
    else setBreaking(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return breaking;
};

export default useBreakpoint;
