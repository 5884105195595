import {Combobox} from "@headlessui/react";
import React, {useCallback, useRef, useState} from "react";
import Link from "next/link";
import {debounce} from "lodash";
import {sendEvent} from "@/core/firebase";
import {moviesSearch} from "@/services";
import {useSelector} from "react-redux";
import {returnValidPoster} from "./Poster";
import Input from "./form/Input";
import {CloseIcon, SearchIcon} from "../../icons";
import type {ContentTypeLight} from "@/types/contentType";
import {useRouter} from "next/router";
import Button from "./form/Button";
import {
  addDataToRecentSearch,
  clearRecentSearches,
  getRecentSearches,
  removeRecentSearchItem,
} from "@/helper/common";
import RecentViews from "./RecentViews";
import {SearchPageResponse} from "@/types/apiType";
import CompanyListSlider from "@/components/CompanyListSlider";
import PeopleListSlider from "@/components/PeopleListSlider";

interface SearchProps {
  className?: string;
}

function Search({className = ""}: SearchProps) {
  const router = useRouter();
  const comboButtonRef = useRef<HTMLButtonElement>(null);

  const searchDebounce = useCallback(debounce(search, 1000), []);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [recentSearches, setRecentSearches] = useState(getRecentSearches);

  const searchPageData: SearchPageResponse = useSelector(
    (state: any) => state.generalReducer.searchPageData
  );
  const [searchResult, setResult] = useState<ContentTypeLight[]>([])

  const onClickRemoveRecentSearchItem = (item: string) => {
    setRecentSearches(removeRecentSearchItem(item));
  };

  const searchOnChange = async (term: string) => {
    setSearchValue(term);
    if (term?.length > 1) searchDebounce(term);
    else {
      setDebouncedSearchValue("");
      searchDebounce.cancel();
      setResult([]);
    }
  };

  const closeFuckingCombobox = () => {
      const events = ["mousedown", "click", "mouseup"];
      events.forEach((mouseEventType) =>
        document.body.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          })
        )
      );
  }

  return (
    <div
      className={`search-container w-100 mx-auto ${className}`}
      style={{maxWidth: 360, width: "max-content"}}
    >
      <div className="header-search">
        <Combobox value={searchValue}>
          {({open}) => (
            <>
              <div className="header-search-trigger">
                <Combobox.Button
                  className="d-none"
                  ref={comboButtonRef}
                />
                <Combobox.Input
                  as={Input}
                  id="search"
                  icon={SearchIcon}
                  iconSize={16}
                  type="search"
                  name="search"
                  placeholder="Film veya dizi ara"
                  autoCapitalize="off"
                  autoComplete="off"
                  spellCheck="true"
                  aria-label="Film veya dizi ara"
                  aria-autocomplete="list"
                  aria-expanded="true"
                  onChange={(e) => searchOnChange(e.target.value)}
                  onFocus={(e) => {
                    if (open) return;
                    comboButtonRef.current?.click();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setRecentSearches(
                        addDataToRecentSearch(searchValue)
                      );
                      router.push(
                        `/search?q=${(e.target as HTMLInputElement).value}`
                      );
                    }
                  }}
                />
              </div>
              <Combobox.Options className="PopoverContent search">
                <div className="header-search-body">
                  {recentSearches.length > 0 && !debouncedSearchValue && (
                    <>
                      {renderRecentSearches()}
                      <hr/>
                    </>
                  )}

                  {!debouncedSearchValue &&
                    <>
                      <RecentViews type="search"/>
                      {renderPopularPeople()}
                      <hr/>
                      {renderPopularProductions()}
                    </>
                  }

                  {debouncedSearchValue && (
                    <>
                      {renderSearchResult()}
                      <hr/>
                      <Link
                        onClick={() =>
                          setRecentSearches(
                            addDataToRecentSearch(debouncedSearchValue)
                          )
                        }
                        href={`/search?q=${debouncedSearchValue}`}
                        className="header-search-more text-center"
                      >
                        "{debouncedSearchValue}" için çıkan tüm sonuçları gör
                      </Link>
                    </>
                  )}
                </div>
              </Combobox.Options>
            </>
          )}
        </Combobox>
      </div>
    </div>
  );

  function renderRecentSearches() {
    return (
      <div className="header-search-recentsearches">
        <div className="header-search-recentsearches-header">
          <p className="header-search-recentsearches-title">
            Son yaptığın aramalar
          </p>

          <Button
            buttonType="unstyled"
            size="md"
            onClick={(e) => {
              e.stopPropagation();
              clearRecentSearches();
              setRecentSearches([]);
            }}
          >
            Tümünü Temizle
          </Button>
        </div>

        <div className="header-search-recentsearches-list">
          {recentSearches.map((recentSearchItem) => (
            <Link
              key={recentSearchItem}
              href={`/search?q=${recentSearchItem}`}
              className="header-search-recentsearches-item">
              <span>{recentSearchItem}</span>{" "}
              <Button
                buttonType="unstyled"
                onClick={(e) => {
                  e.preventDefault();
                  onClickRemoveRecentSearchItem(recentSearchItem);
                }}
              >
                <CloseIcon fontSize={14}/>
              </Button>
            </Link>
          ))}
        </div>
      </div>
    );
  }

  function renderSearchResult() {
    return (
      <div className="header-search-result">
        <div className="header-search-result-header">
          <p className="header-search-result-title">
            "{debouncedSearchValue}" için çıkan sonuçlar
          </p>
        </div>

        {!isLoading && (!searchResult || searchResult?.length == 0) ? (
          <p>Herhangi bir sonuç bulunamadı</p>
        ) : (
          <ul
            className="header-search-result-list custom-scrollbar overflow-auto"
            style={{
              maxHeight: 500,
              overscrollBehavior: "contain",
            }}
          >
            {searchResult.map((i: ContentTypeLight) => (
              <Link
                key={i.id}
                onClick={(e) => {
                  e.preventDefault();
                  setRecentSearches(addDataToRecentSearch(debouncedSearchValue));
                  router.push(`/${i.slugUrl}`)
                }}
                className="header-search-result-item"
                href={`/${i.slugUrl}`}
              >
                <img
                  className="me-2"
                  src={returnValidPoster(i.imageUrl, "w342")}
                  alt=""
                />
                <div>
                  <p>{i.title}</p>
                  <span className="mt-1">{i.year}</span>
                </div>
              </Link>
            ))}
          </ul>
        )}
      </div>
    );
  }

  function renderPopularPeople() {
    if (!searchPageData.popularPeople.length) return;
    return (
      <div>
        <div className="header-search-popular-header mb-2">
          <p className="header-search-popular-title">Popüler Kişiler</p>
        </div>
        <PeopleListSlider list={searchPageData.popularPeople}/>
      </div>
    );
  }

  function renderPopularProductions() {
    if (!searchPageData.popularProductions.length) return;
    return (
      <div>
        <div className="header-search-popular-header mb-2">
          <p className="header-search-popular-title">Popüler Yapım Şirketleri</p>
        </div>
        <CompanyListSlider list={searchPageData.popularProductions}/>
      </div>
    )
  }

  async function search(term: string) {
    setDebouncedSearchValue(term);
    setLoading(true);
    sendEvent("search", {
      search_term: term,
    });
    const response = await moviesSearch({pageSize: 20, page: 1, title: term});
    if (response.isSucceed) {
      setResult(response.data.result || []);
    } else {
      setResult([]);
    }
    setLoading(false);
  }
}

export default Search;
