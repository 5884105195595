import {forwardRef, useState} from "react";
import {EyeOffIcon, EyeOnIcon} from "@/icons/index";
import type {InputHTMLAttributes, ForwardedRef} from 'react';
import type {SVGIconType} from "@/types/dataType";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: any;
  label?: string;
  iconSize?: number;
  icon?: SVGIconType;
}

function Input(props: InputProps, ref?: ForwardedRef<HTMLInputElement>) {
  const {
    icon: Icon,
    iconSize = 24,
    className,
    error,
    label,
    placeholder,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
      <div
          className={
              "input-custom" +
              (Icon ? " input-custom--withIcon" : "") +
              (className ? " " + className : "") +
              (error ? " input-custom--error" : "") +
              (label ? " input-custom--withLabel" : "")
          }
      >
        <div className="position-relative">
          {Icon && (
              <Icon
                  className="position-absolute top-50 start-0 translate-middle-y ms-3"
                  fontSize={iconSize}
              />
          )}
          <input
              ref={ref}
              {...rest}
              placeholder={placeholder || " "}
              type={rest.type === "password" && showPassword ? "text" : rest.type}
          />
          {label && <label className={"input-custom__label"} style={!Icon ? {left: 16} : undefined}>{label}</label>}
          {rest.type === "password" && (
              <button
                  type="button"
                  className="position-absolute top-50 end-0 translate-middle-y me-3 password-toggle-btn"
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
              >
                {showPassword ? (
                    <EyeOnIcon fontSize={24}/>
                ) : (
                    <EyeOffIcon fontSize={24}/>
                )}
              </button>
          )}
        </div>
        {!!error && <div className="input-custom__error">{error}</div>}
      </div>
  );
}

export default forwardRef(Input);
