import React, {memo} from 'react';
import {useSelector} from "react-redux";
import Image from "next/image";
import * as Popover from "@radix-ui/react-popover";
import Button from "@/components/form/Button";

interface GenreSelectionBoxProps {
    selectedGenres: string[],
    onChange: (genre: string) => void;
    showSelected?: boolean;
}

function GenreSelectionBox({selectedGenres, onChange, showSelected}: GenreSelectionBoxProps) {
    const genreList: string[] = useSelector((state: any) => state.generalReducer.genreList);

    return (
        <Popover.Root>
            <Popover.Trigger asChild type='button'>
              <Button buttonType='black'>
                   <span style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    {showSelected && selectedGenres.length ? selectedGenres.join(', ') : 'Tür'}
                   </span>
                    <Image src="/icons/arrowDown.svg" alt="arrowdown" width={8} height={8}/>
                </Button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content className="PopoverContent" sideOffset={5} style={{width: 'unset'}}>
                    <div className='genre-selection-body custom-scrollbar'>
                        {genreList.map(key =>
                            <div className='genre-selection-item' key={key} data-selected={selectedGenres.includes(key)} onClick={() => changeGenre(key)}>
                                {key}
                            </div>
                        )}
                    </div>
                    <Popover.Arrow className="PopoverArrow"/>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )

    function changeGenre(genre: string) {
        onChange(genre);
    }
}

export default memo(GenreSelectionBox);
