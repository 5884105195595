/**
 *Created By Barbaros Susuz on Jun, 2019
 **/

import App from "next/app";
import {connect} from "react-redux";
import "../../public/styles/_globals.scss";
import {wrapper} from "../store";
import {initializeFirebase} from "../core/firebase";
import {getRecentViews, isBrowser} from "../helper/common";
import {getCachedConfig, getCachedSearchPage} from "../services";
import AppContainer from "../layout/AppContainer";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {constantConfig} from "../helper/config";
import {getCookie} from "cookies-next";
import {TOKEN_COOKIE_NAME} from "../helper/constants";
import NProgress from "nprogress";
import Router from "next/router";
import isBot from "../helper/isBot";
import {getMyUser} from "../services/auth";
import {Open_Sans} from 'next/font/google'

const open_sans = Open_Sans({subsets: ['latin']})

class InitialApp extends App {
  constructor(props) {
    super(props);
    if (isBrowser()) {
      this.allEvents(true);
      if (!isBot()) {
        require("bootstrap/dist/js/bootstrap");
        initializeFirebase();
      }
    }
  }

  componentDidMount() {
    this.populateSearchPage();
    this.populateRecentViews();
  }

  routeChangeStart = (url) => {
    NProgress.start();
  };

  routeChangeComplete = (url) => {
    // if (!this.state.isBot && typeof gtag !== 'undefined') {
    //     let gtagObj = {
    //         pageTitle: '',
    //         pageLocation: window.location.origin + Router.router.asPath,
    //         pagePath: Router.router.asPath,
    //         type: Router.router.query.type,
    //         id: Router.router.query.id
    //     };
    //
    //     gtag('config', 'UA-12345678-9', gtagObj);
    // }
    //


    NProgress.done();
  };

  routeChangeError = () => {
    NProgress.done()
  };

  allEvents(type) {
    if (type) {
      //router events
      Router.events.on('routeChangeStart', this.routeChangeStart);
      Router.events.on('routeChangeComplete', this.routeChangeComplete);
      Router.events.on('routeChangeError', this.routeChangeError);
    } else {
      //router events
      Router.events.off('routeChangeStart', this.routeChangeStart);
      Router.events.off('routeChangeComplete', this.routeChangeComplete);
      Router.events.off('routeChangeError', this.routeChangeError);
    }
  }

  async populateSearchPage() {
    try {
      const r = await getCachedSearchPage();
      if (r.isSucceed)
        this.props.setSearchPageData(r.data);
    } catch {}
  }

  populateRecentViews() {
    const recentViews = getRecentViews();
    this.props.setRecentViews(recentViews);
  }

  render() {
    const {Component, pageProps} = this.props;
    return (
      <>
        <style jsx global>{`
            :root {
                --font-open-sans: ${open_sans.style.fontFamily};
            }
        `}
        </style>
        <GoogleOAuthProvider clientId={constantConfig.GOOGLE_OAUTH_CLIENT_ID}>
          <AppContainer>
            <Component {...pageProps} />
          </AppContainer>
        </GoogleOAuthProvider>
      </>
    );
  }
}

InitialApp.getInitialProps = async (appContext) => {
  // Server Side
  if (!isBrowser()) {
    const context = appContext.ctx;
    await getUser(context);
    const response = await getCachedConfig(context);
    if (response.isSucceed) {
      const streamList = [];
      response.data.streamingServices.forEach(i => {
        if (i.isActive) {
          streamList.push(i);
        }
      })
      context.store.dispatch({
        type: "SET_INITIAL_CONFIG",
        payload: {
          genreList: response.data.genres,
          streamList: streamList,
          sortList: response.data.sortBy,
          tmdbBaseUrl: response.data.tmdbBaseUrl,
          countries: response.data.countries
        },
      });
    }
  }

  // Get pages
  const appProps = await App?.getInitialProps(appContext);
  return {...appProps};
};

async function getUser(context) {
  const token = getCookie(TOKEN_COOKIE_NAME, {res: context?.res, req: context?.req});
  if (token) {
    const response = await getMyUser(context);
    if (response.isSucceed) {
      context.store.dispatch({
        type: "SET_USER",
        payload: response.data,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  filters: state.generalReducer.filters,
  skip: state.generalReducer.skip
});

const mapDispatchToProps = (dispatch) => ({
  setSearchPageData: (data) =>
    dispatch({
      type: "SET_SEARCH_PAGE_DATA",
      payload: data,
    }),
  setRecentViews: (data) =>
    dispatch({
      type: "SET_RECENT_VIEWS",
      payload: data,
    }),
});

export default wrapper.withRedux(connect(mapStateToProps, mapDispatchToProps)(InitialApp));
