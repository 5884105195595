/**
 *Created By Barbaros Susuz on Jan, 2021
 **/

import {produce} from 'immer';
import {NUMBER_LIST_DEFAULT} from "@/helper/constants";
import { addDataToRecentView, clearRecentViews } from '@/helper/common';

export const initialState = {
    data: [],
    allDataCount: 0,
    asPathWhenDataSaved: '',
    recentViews: [],
    skip: 1,
    filters: {
        numbers: {
            year: {start: NUMBER_LIST_DEFAULT.year.start, end: NUMBER_LIST_DEFAULT.year.end},
            imdb: {start: NUMBER_LIST_DEFAULT.imdb.start, end: NUMBER_LIST_DEFAULT.imdb.end},
            metascore: {start: NUMBER_LIST_DEFAULT.metascore.start, end: NUMBER_LIST_DEFAULT.metascore.end},
            rotten: {start: NUMBER_LIST_DEFAULT.rotten.start, end: NUMBER_LIST_DEFAULT.rotten.end},
            tmdb: {start: NUMBER_LIST_DEFAULT.tmdb.start, end: NUMBER_LIST_DEFAULT.tmdb.end},
        },
    },
    genreList: [],
    streamList: [],
    searchPageData: {
        popularPeople: [],
        popularProductions: []
    },
    sortList: [],
    homePageData: {},
    liveTvData: {},
    tmdbBaseUrl: 'https://image.tmdb.org/t/p/original/',
};


const generalReducer = produce((draft = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case 'SET_DATA': {
            draft.data = payload.data;
            draft.allDataCount = payload.allDataCount;
            draft.skip = 2;
            draft.asPathWhenDataSaved = payload.asPath;
            return draft;
        }
        case 'INCREASE_DATA': {
            draft.data = [...draft.data, ...payload.data];
            draft.allDataCount = payload.allDataCount;
            draft.skip += 1;
            return draft;
        }
        case 'ADD_RECENT_VIEW': {
             draft.recentViews = addDataToRecentView(payload);
            return draft;
        }
        case 'CLEAR_RECENT_VIEWS': {
            draft.recentViews = [];
            clearRecentViews();
            return draft;
        }
        case 'SET_RECENT_VIEWS': {
            draft.recentViews = payload;
            return draft;
        }
        case 'SET_FILTER_NUMBERS': {
            draft.filters.numbers[payload.key] = payload.value;
            return draft;
        }
        case 'RESET_FILTER': {
            draft.filters.numbers = initialState.filters.numbers;
            return draft;
        }
        case 'SET_INITIAL_CONFIG': {
            draft.genreList = payload.genreList;
            draft.streamList = payload.streamList;
            draft.sortList = payload.sortList;
            draft.countries = payload.countries;
            return draft;
        }
        case 'SET_HOME_PAGE_DATA': {
            draft.homePageData = payload;
            return draft;
        }
        case 'SET_SEARCH_PAGE_DATA': {
            draft.searchPageData = payload;
            return draft;
        }
        case 'SET_LIVE_TV_DATA': {
            draft.liveTvData = payload;
            return draft;
        }
        default: {
            return draft;
        }
    }
});

export default generalReducer;
