import {isBrowser} from "@/helper/common";

export default function isBot(userAgent?: string) {
    if (!isBrowser() && !userAgent) return null;
    const agent = userAgent ? userAgent : window.navigator.userAgent;

    const botUserAgentsArray = [
        'googlebot',
        'googlebot-image',
        'googlebot-news',
        'googlebot-video',
        'google-inspectiontool',
        'bingbot',
        'linkedinbot',
        'mediapartners-google',
        'lighthouse',
        'insights',
    ];

    var isBotUserAgent = 0;
    for (var j = 0; j < botUserAgentsArray.length; j++) {
        if (agent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1) {
            isBotUserAgent = 1;
            break;
        }
    }
    return isBotUserAgent == 1;
}
