import React, { useEffect, useState } from "react";
import Image from "next/image";
import { Textfit } from "react-textfit";

interface CompanyLogoProps {
  url: string;
  companyName: string;
  className?: string;
  children?: React.ReactNode;
  style?: any;
}

export default function CompanyLogo({
  url,
  companyName,
  className = "",
  children,
  style,
}: CompanyLogoProps) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (!url) setImageError(true);
    else setImageError(false);
  }, [url]);

  return (
    <div
      className={`companyLogo-body ${className}`}
      style={style}
    >
      {imageError ? (
        <div className="companyLogo-fallback">
            <div className="companyLogo-fallback-text"><p>{companyName.toUpperCase()}</p></div>
        </div>
      ) : (
        url ?
        <Image
          fill
          unoptimized
          className="companyLogo-image"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit:"contain"
          }}
          src={url}
          alt={companyName}
          onError={handleImageError}
        /> : <></>
      )}
      {children}
    </div>
  );
}
