/**
 *Created By Barbaros Susuz on Jan, 2021
 **/
import {createStore} from "redux";
import {HYDRATE, createWrapper} from 'next-redux-wrapper'
import combinedReducer from './reducers';

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        }
        return nextState
    } else {
        return combinedReducer(state, action)
    }
}

export default function initStore(preloadedState = {}) {
    return createStore(
        reducer,
        // preloadedState
        // composeWithDevTools(applyMiddleware())
    )
}

export const wrapper = createWrapper(initStore, {debug: false});
