import { forwardRef, useState } from "react";
import { EyeOffIcon, EyeOnIcon } from "../../../icons";

function TextareaWRef(
  { icon: Icon, iconSize = 24, className, error, label, ...rest },
  ref
) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div
      className={
        "input-custom textarea-custom" +
        (Icon ? " input-custom--withIcon" : "") +
        (className ? " " + className : "") +
        (error ? " input-custom--error" : "") +
        (label ? " input-custom--withLabel" : "")
      }
    >
      <div className="position-relative">
        {Icon && (
          <Icon
            className="position-absolute top-50 start-0 translate-middle-y ms-3"
            fontSize={iconSize}
          />
        )}
        {label && <label className={""}>{label}</label>}
        <textarea
          ref={ref}
          {...rest}
          type={rest.type === "password" && showPassword ? "text" : rest.type}
        ></textarea>
        {rest.type === "password" && (
          <button
            type="button"
            className="position-absolute top-50 end-0 translate-middle-y me-3 password-toggle-btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOnIcon fontSize={24} />
            ) : (
              <EyeOffIcon fontSize={24} />
            )}
          </button>
        )}
      </div>
      {error && (
        <div className="input-custom__error textarea-custom__error">
          {error}
        </div>
      )}
    </div>
  );
}

const Textarea = forwardRef(TextareaWRef);

export default Textarea;
