import Image from "next/image";
import React from "react";
import useRenderInBrowser from "@/hooks/useRenderInBrowser";
import {routes} from "@/helper/routes";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import RecentViews from "@/components/RecentViews";
import {STORE_URLS} from "@/helper/constants";

const links = [
  {
    label: 'Öneri ve Trendler',
    href: routes.home
  },
  {
    label: 'Canlı Tv',
    href: routes.liveTv.index
  },
  {
    label: 'Popüler Filmler',
    href: routes.contents.movie
  },
  {
    label: 'Popüler Diziler',
    href: routes.contents.series
  },
  {
    label: 'Tavsiye Listeleri',
    href: routes.recommendedList.index
  },
  {
    label: 'Film Ruleti',
    href: routes.roulette
  },
  {
    label: 'Popüler Netflix İçerikleri',
    href: `${routes.contents.index}?streams=8`
  },
  {
    label: 'Popüler Disney İçerikleri',
    href: `${routes.contents.index}?streams=337`
  },
  {
    label: 'Popüler Amazon Prime İçerikleri',
    href: `${routes.contents.index}?streams=119`
  },
  {
    label: 'Popüler BluTv İçerikleri',
    href: `${routes.contents.index}?streams=341`
  },
];
export default function Footer() {
  const renderingInBrowser = useRenderInBrowser();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.authReducer.isLoggedIn);
  const login = () => {
    if (!isLoggedIn) {
      return dispatch({
        type: "OPEN_MODAL",
        payload: {key: "login"},
      });
    }
  };

  return (
    <footer id='footer'>
      <div className='footer-inner py-3'>
        <div className='container-fluid container-max-width'>
          {renderApps()}
        </div>
        <div className='footer-recentviews container-fluid container-max-width'>
          {renderingInBrowser && <RecentViews type="footer"/>}
        </div>
        {!isLoggedIn &&
          <div className='footer-registeralert text-center' style={{backgroundColor: '#202020'}}>
            <span className='cursor-p' onClick={login}>Keşfetmeye hazır mısın? <span className='text-brand footer-registeralert-highlight'>Hemen üye ol</span> ve en sevdiğin filmleri ve dizileri bul!</span>
          </div>
        }
        <div className='container-fluid container-max-width'>
          <div className='footer-links'>
            {links.map(i =>
              <Link key={i.href} href={i.href} className='footer-links-item'>
                {i.label}
              </Link>
            )}
          </div>
          {renderSocialMedia()}
          <p className='footer-description'>
            Reklam, iletişim ve şikayet için <span>info@neredeizlenir.com</span> adresinden iletişime geçebilirsiniz.
          </p>
          <h4 className='footer-title mt-3'>
            Nerede izlenir? 2020 - {new Date().getFullYear()}
          </h4>
        </div>
      </div>
    </footer>
  )

  function renderApps() {
    return (
      <div className='footer-mobileapp'>
        <div className="footer-mobileapp-content-wrapper">
          <div className="footer-mobileapp-content">
            <p className='footer-mobileapp-header'><span className="text-brand">Mobil uygulamamızı</span> denediniz mi?</p>
            <p className="footer-mobileapp-description">
              Film ve dizi tutkunları için ideal bir rehber! istediğiniz içeriğin hangi platformlarda izlenebileceğini anında gösterir <span className="text-brand">Hemen İndirin!</span>
            </p>
            <ul className="footer-mobileapp-list" role="presentation">
              <li className="footer-mobileapp-list-item" role="presentation">
                <a
                  title="Google Play"
                  role="button"
                  aria-label="Google Play"
                  aria-disabled="false"
                  target="_blank"
                  rel="nofollow noopener"
                  href={STORE_URLS.android}
                >
                  <Image src="/images/google-play.png" alt="Nerede izlenir google play" title="Nerede izlenir google play" width={203} height={60}/>
                </a>
              </li>
              <li className="footer-mobileapp-list-item" role="presentation">
                <a
                  title="Apple Store"
                  role="button"
                  aria-label="Apple Store"
                  aria-disabled="false"
                  target="_blank"
                  rel="nofollow noopener"
                  href={STORE_URLS.ios}
                >
                  <Image src="/images/apple-store.png" alt="Nerede izlenir apple store" title="Nerede izlenir apple store" width={203} height={60}/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-mobileapp-right-wrapper">
          <div className="footer-mobileapp-right">
            <div className="footer-mobileapp-image-wrapper">
              <Image className="footer-mobileapp-image" src="/images/mobile-app.png" alt="Nerede izlenir apple store" title="Nerede izlenir apple store" width={586} height={592}/>
            </div>
          </div>
        </div>


      </div>
    )
  }

  function renderSocialMedia() {
    return (
      <div className='footer-socialmedia'>
        <p className="footer-socialmedia-title">Sosyal medya</p>
        <ul className="footer-socialmedia-links inline-list gap-3" role="presentation">
          <li className="inline-list-item" role="presentation">
            <a
              title="Instagram"
              role="button"
              aria-label="Instagram"
              aria-disabled="false"
              target="_blank"
              rel="nofollow noopener"
              href="https://www.instagram.com/nerede.izlenir/"
            >
              <Image src="/images/instagram.svg" alt="Nerede izlenir instagram" title='Nerede izlenir instagram' width={34} height={34}/>
            </a>
          </li>
          <li className="inline-list-item" role="presentation">
            <a
              title="Youtube"
              role="button"
              aria-label="Youtube"
              aria-disabled="false"
              target="_blank"
              rel="nofollow noopener"
              href="https://www.youtube.com/@neredeizlenir"
            >
              <Image src="/images/youtube.svg" alt="Nerede izlenir youtube" title='Nerede izlenir youtube' width={34} height={34}/>
            </a>
          </li>
          <li className="inline-list-item" role="presentation">
            <a
              title="Tiktok"
              role="button"
              aria-label="Tiktok"
              aria-disabled="false"
              target="_blank"
              rel="nofollow noopener"
              href="https://www.tiktok.com/@nerede.izlenir"
            >
              <Image src="/images/tiktok.svg" alt="Nerede izlenir tiktok" title='Nerede izlenir tiktok' width={34} height={34}/>
            </a>
          </li>
          <li className="inline-list-item" role="presentation">
            <a
              title="Facebook"
              role="button"
              aria-label="Facebook"
              aria-disabled="false"
              target="_blank"
              rel="nofollow noopener"
              href="https://www.facebook.com/profile.php?id=100093316034755"
            >
              <Image src="/images/facebook.svg" alt="Nerede izlenir facebook" title='Nerede izlenir facebook' width={34} height={34}/>
            </a>
          </li>
          <li className="inline-list-item" role="presentation">
            <a
              title="Twitter"
              role="button"
              aria-label="Twitter"
              aria-disabled="false"
              target="_blank"
              rel="nofollow noopener"
              href="https://twitter.com/neredeizlenir"
            >
              <Image src="/images/twitter.svg" alt="Nerede izlenir twitter" title='Nerede izlenir twitter' width={34} height={34}/>
            </a>
          </li>
        </ul>
      </div>
    )
  }
}
