import { useSelector, useDispatch } from "react-redux";
import Button from "./form/Button";
import ContentListSlider from "./ContentListSlider";
import { ClockFastIcon } from "@/icons/index";

export interface RecentViewsProps {
  type: "search" | "footer";
}

export default function RecentViews({ type }: RecentViewsProps) {
  const dispatch = useDispatch();
  const recentViews = useSelector(
    (state: any) => state.generalReducer.recentViews
  );

  if(recentViews.length == 0) return <></>

  return (
    <div>
      <div className={`recentviews recentviews-${type}`}>
        <div className="recentviews-header">
          <div className="recentviews-header-left">
            {type == "footer" && <ClockFastIcon className="d-none d-lg-block" fontSize={32} color="#fff" />}
            <p className="recentviews-title">{type == "footer" ? "Son görüntülediğin içerikler" : "İncelediğin son içerikler"}</p>
          </div>

          <Button
            buttonType="unstyled"
            size="md"
            onClick={(e) => {
              dispatch({
                type: "CLEAR_RECENT_VIEWS",
              });
            }}
          >
            Tümünü Temizle
          </Button>
        </div>
        <div>
          <ContentListSlider
            list={recentViews.map((item: any) => item.data)}
            type={type == "search" ? "searchRecentView" : "recentView"}
          />
        </div>
      </div>
      { type == "search" && <hr style={{marginTop:16}}/>}
    </div>
  );
}
