export const constantConfig = {
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  JWT_SECRET: process.env.NEXT_PUBLIC_JWT_SECRET,
  JWT_ISS: process.env.NEXT_PUBLIC_JWT_ISS,
  JWT_AUD: process.env.NEXT_PUBLIC_JWT_AUD,
  GOOGLE_OAUTH_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
  FB_APP_ID: process.env.NEXT_PUBLIC_FB_APP_ID,
};

export const firebasePaths = {
  RECOMMENDED_LIST: '/recommended_list_2',
  LIVE_TV_GENERAL_PATH: '/liveTv',
}
