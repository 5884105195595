import {useMemo} from "react";
import {useSelector} from "react-redux";
import type {ContentType} from "@/types/contentType";
import type {UserListType} from "@/types/listType";
import {UserListTypeEnum} from "@/types/enum";

interface UseContentInListReturnType {
    isInWatchList: boolean;
    isInSeenList: boolean;
    userLists: UserListType[];
}
export default function useContentInList({contentId}: {contentId: ContentType['id']}): UseContentInListReturnType {
    const _userLists: UserListType[] = useSelector((state: any) => state.authReducer.user.userLists) || [];
    return useMemo(() => {
        let isInWatchList = false, isInSeenList = false;

        const watchList = _userLists.filter(i => i.type !== UserListTypeEnum.Seen);
        for (let i in watchList) {
            if (watchList[i].itemIds.includes(contentId)) {
                isInWatchList = true;
                break;
            }
        }

        const seenList = _userLists.filter(i => i.type === UserListTypeEnum.Seen);
        for (let i in seenList) {
            if (seenList[i].itemIds.includes(contentId)) {
                isInSeenList = true;
                break;
            }
        }
        return {userLists: _userLists, isInWatchList, isInSeenList};
        // end of useMemo
    }, [_userLists, contentId]);
}
