import toast from "@/helper/toast";
import classNames from "classnames";
import Link from "next/link";
import { ToastPosition, type Toast } from "react-hot-toast";

export function ToastBar(t: Toast, position: ToastPosition) {

  const animationStyle = t.height
    ? {
        animation: t.visible
          ? "enterToastAnimation 0.35s cubic-bezier(.21,1.02,.73,1) forwards"
          : "exitToastAnimation 0.4s forwards cubic-bezier(.06,.71,.55,1)",
      }
    : {
        opacity: 0,
      };

      const toastPosition = t.position || position;

  return (
    <div
      className={classNames(
        "ntoast",
        `ntoast--${t.type}`,
        t.icon && "ntoast--with-icon"
      )}
      style={{
        "--factor": toastPosition?.includes("bottom")  ? "-1" : "1",
        ...animationStyle,
      }}
    >
      {t.icon && <div className="ntoast-icon">{t.icon}</div>}

      <div className="ntoast-content">
        <div className="ntoast-message">
          {typeof t.message == "function"
            ? t.message(t)
            : t.message}
        </div>
        {t.action &&<div className="ntoast-action" onClick={() => t.closeOnAction && toast.dismiss(t.id)}> {renderAction(t)}</div>}
      </div>
    </div>
  );

  function renderAction(t:Toast){
    if(!t.action) return null;
  
    if(typeof t.action == "function") return t.action(t)
    if(typeof t.action == "object") return t.action;
  
    return <Link href={t.action} className="text-reset d-block h-100">Gör</Link>
  }
}