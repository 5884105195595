/**
 *Created By Barbaros Susuz on Jan, 2021
 **/

import { produce } from "immer";

export const initialState = {
  modals: {
    login: false,
    register: false,
    forgotPassword: false,
    addList: false,
  },
};

const uiReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "OPEN_MODAL": {
      Object.keys(draft.modals).forEach((key) => {
        if (key !== payload.key) {
          draft.modals[key] = false;
        }
      });
      draft.modals[payload.key] = true;
      return draft;
    }
    case "CLOSE_MODAL": {
      draft.modals[payload.key] = false;
      return draft;
    }
    case "SET_MODAL": {
      draft.modals[payload.key] = payload.value;
      return draft;
    }
    default: {
      return draft;
    }
  }
});

export default uiReducer;
