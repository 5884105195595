import {endPoints} from "@/helper/endPoints";
import {api, type ApiProps} from "@/helper/apiHelper";
import type {BaseResultResponse, ConfigResponse, HomePageResponse, LiveTvResponse, SearchPageResponse} from "@/types/apiType";
import type {ContentType, ContentTypeLight, PeopleAltType, ProductionCompanyType} from "@/types/contentType";

export const getHomePage = async (ctx?: ApiProps["ctx"]) => {
  return await api<HomePageResponse>({ url: endPoints.homePage, method: "GET", ctx });
};

export const moviesGetByFilter = async (
  data: any,
  progress?: boolean,
  ctx?: ApiProps["ctx"]
) => {
  return await api<BaseResultResponse<ContentTypeLight>>({ url: endPoints.moviesGetByFilter, data, ctx, progress });
};

export const moviesGetByFilterDetail = async (
  data: {idList: string[], page: number, pageSize: number},
  ctx?: ApiProps["ctx"]
) => {
  return await api<BaseResultResponse<ContentType>>({ url: endPoints.moviesGetByFilterDetail, data, ctx });
};

export const moviesGetDetail = async (id: string, ctx?: ApiProps["ctx"]) => {
  return await api<ContentType>({
    url: endPoints.moviesGetDetail + "?id=" + id,
    method: "GET",
    ctx,
  });
};

export const getInitialConfig = async (ctx?: ApiProps["ctx"]) => {
  return await api<ConfigResponse>({
    url: endPoints.getInitialConfig,
    method: "GET",
    ctx,
  });
};

export const getCachedConfig = async (ctx?: ApiProps["ctx"]) => {
  return await api<ConfigResponse>({
    url: endPoints.local.getCachedConfig,
    localApi: true,
    method: "GET",
    ctx,
  });
};


export const moviesRoulette = async (data: any, ctx?: ApiProps["ctx"]) => {
  return await api<ContentType>({ url: endPoints.moviesRoulette, data, ctx });
};

export const moviesSearch = async (data: any, ctx?: ApiProps["ctx"]) => {
  return await api<BaseResultResponse<ContentTypeLight>>({ url: endPoints.moviesSearch, data, ctx });
};

export const getSearchPage = async (ctx?: ApiProps["ctx"]) => {
  return await api<SearchPageResponse>({ url: endPoints.getSearchPage, ctx, method: 'GET' });
};

export const getCachedSearchPage = async (ctx?: ApiProps["ctx"]) => {
  return await api<SearchPageResponse>({ url: endPoints.local.getCachedSearchPage, ctx, method: 'GET', localApi: true });
};

export const getPeopleById = async (id: string, ctx?: ApiProps["ctx"]) => {
  return await api<PeopleAltType>({ url: `${endPoints.getPeopleById}/${id}`, ctx, method: 'GET' });
};

export const getProductionById = async (id: string, ctx?: ApiProps["ctx"]) => {
  return await api<ProductionCompanyType>({ url: `${endPoints.getProductionById}/${id}`, ctx, method: 'GET' });
};

export const getLiveTv = async (ctx?: ApiProps["ctx"]) => {
  return await api<LiveTvResponse>({url: endPoints.local.getLiveTv, ctx, method: "GET", localApi: true});
};

