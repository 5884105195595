export const routes = {
  notFound: '/404',
  home: "/",
  roulette: "/roulette",
  contents: {
    index: "/icerikler",
    movie: "/film",
    series: "/dizi",
  },
  recommendedList: {
    index: "/tavsiye-listesi",
  },
  watchlist: "/watchlist",
  search: "/search",
  people: "/insan",
  production: "/yapimci-firma",
  profile: {
    index: "/profil",
    lists: "/profil/liste",
    comments: "/profil/degerlendirmeler",
    changePassword: "/profil/sifre",
    notifications: "/profil/bildirimler",
  },
  liveTv: {
    index: "/canli-tv",
    category: "/canli-tv/kategori",
  }
};
