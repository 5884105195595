/**
 *Created By Barbaros Susuz on Jun, 2023
 **/
import {useEffect, useRef} from 'react';
import Router from 'next/router';
import type {NextRouter} from 'next/router';

interface PosType {
    [url: string]: {
        x?: number;
        y?: number;
    }
}

export default function useScrollRestoration(router: NextRouter) {
    const shouldScrollRestore = useRef(false);
    const positions = useRef<PosType>({});
    // useEffect(() => {
    //     if (Object.keys(pos).length > 100) {
    //         positions.current = {};
    //     }
    // }, [pos]);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';

            // local state tutuyorum
            // const onBeforeUnload = (event: any) => {
            //     saveScrollPos(router.asPath);
            //     delete event['returnValue'];
            // };

            const onRouteChangeStart = () => {
                saveScrollPos(router.asPath);
            };

            const onRouteChangeComplete = (url: string) => {
                if (shouldScrollRestore.current) {
                    shouldScrollRestore.current = false;
                    restoreScrollPos(url);
                }
            };

            // window.addEventListener('beforeunload', onBeforeUnload);
            Router.events.on('routeChangeStart', onRouteChangeStart);
            Router.events.on('routeChangeComplete', onRouteChangeComplete);
            Router.beforePopState(() => {
                shouldScrollRestore.current = true;
                return true;
            });

            return () => {
                // window.removeEventListener('beforeunload', onBeforeUnload);
                Router.events.off('routeChangeStart', onRouteChangeStart);
                Router.events.off('routeChangeComplete', onRouteChangeComplete);
                Router.beforePopState(() => true);
            };
        }
    }, [router]);

    function saveScrollPos(url: string) {
        const scrollPos = {x: window.scrollX, y: window.scrollY};
        positions.current = {...positions.current, [router.asPath]: scrollPos};
    }

    function restoreScrollPos(url: string) {
        const scrollPos = positions.current[url];
        if (scrollPos) {
            window.scrollTo({
                top: scrollPos.y,
                left: scrollPos.x,
                behavior: 'instant'
            });
            if (Object.keys(positions).length > 100) {
                positions.current = {};
            }
        }
    }

}

// SESSION
// function saveScrollPos(url) {
//     const scrollPos = {x: window.scrollX, y: window.scrollY};
//     sessionStorage.setItem(url, JSON.stringify(scrollPos));
// }
//
// function restoreScrollPos(url) {
//     const scrollPos = JSON.parse(sessionStorage.getItem(url));
//     if (scrollPos) {
//         window.scrollTo({
//             top: scrollPos.y,
//             left: scrollPos.x,
//             behavior: 'instant'
//         });
//     }
// }
