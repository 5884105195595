import {
  Renderable,
  ToastOptions,
  Toast,
  ValueOrFunction,
  toast as originalToast,
} from "react-hot-toast";

type Message = ValueOrFunction<Renderable, Toast>;

interface CustomToastOptions extends ToastOptions {
  action?: Message;
  closeOnAction?: boolean;
}

const toast = (message: Message, opts?: CustomToastOptions) =>
  originalToast(message, opts);
toast.success = (message: Message, opts?: CustomToastOptions) =>
  originalToast.success(message, opts);
toast.error = (message: Message, opts?: CustomToastOptions) =>
  originalToast.error(message, opts);
toast.loading = (message: Message, opts?: CustomToastOptions) =>
  originalToast.loading(message, opts);
toast.custom = (message: Message, opts?: CustomToastOptions) =>
  originalToast.custom(message, opts);

toast.dismiss = (toastId: string | undefined) => originalToast.dismiss(toastId);

export default toast;

export { toast };
