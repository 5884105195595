// @ts-nocheck

import {useCallback, useMemo} from "react";
import {useSearchParams} from "next/navigation";
import {useRouter} from "next/router";
import {isArray} from "lodash";
import type {ParsedUrlQuery} from "querystring";

// TODO mediaType ozel bir duruma sahip
function useFilterQueryParams(defaultValues = {}): [Object, (key: string, value: any) => void, ParsedUrlQuery] {
    const router = useRouter();
    const searchParams = useSearchParams();

    const queryWithDefaults = useMemo(() => {
        const pQ = {...defaultValues, ...router.query};
        for (let key in router.query) {
            if (router.query.hasOwnProperty(key)) {
                // query has multiple value (array)
                if (router.query[key]?.includes(',')) {
                    pQ[key] = router.query[key].split(',');
                }
                // does not includes multiple value and has default value then return as array
                else if (defaultValues.hasOwnProperty(key) && isArray(defaultValues[key])) {
                    pQ[key] = [router.query[key]];
                }
            }
        }
        return pQ;
    }, [router.query]);

    // Get a new searchParams string by merging the current
    // searchParams with a provided key/value pair
    const createQueryString = useCallback((key: string, value: any, deleteMediaType: boolean) => {
        const params = new URLSearchParams(searchParams);
        // virgul koyabilmek adina siliyorum mediaType'i
        if (deleteMediaType) params.delete('mediaType');
        if (params.has(key)) {
            if (!value || (isArray(value) && !value.length)) {
                params.delete(key);
            } else {
                params.set(key, value);
            }
        } else if (value) {
            params.append(key, value);
        }
        // console.log(`${key}:${value}`, ` sonuc => ${params.toString()}`)

        return params.toString();
    }, [searchParams]);

    const setQuery = useCallback(function (key: string, value: any) {
        // virgul koyabilmek icin decode ediyorum, ancak icerisinde "sayfa query param"i da olunca virgul nedense koymuyor.
        // bu yuzden createQueryString icerisinde mediaType siliyorum
        const queryString = createQueryString(key, value, router.query.hasOwnProperty('mediaType'));
        const decodedQuery = decodeURIComponent(queryString);
        let url = decodedQuery ? `${router.pathname}?${decodedQuery}` : router.pathname;
        if (router.query.hasOwnProperty('mediaType')) {
            url = url.replace('[mediaType]', router.query.mediaType);
        }
        router.replace(url, undefined, {shallow: true});
    }, [createQueryString]);

    return [queryWithDefaults, setQuery, router.query];
}

export default useFilterQueryParams;
