import {setCookie, deleteCookie} from "cookies-next";
import {type AuthorizationType, REFRESH_COOKIE_NAME, TOKEN_COOKIE_NAME} from "@/helper/constants";
import {isBrowser} from "@/helper/common";
import Router from "next/router";
import type {NextPageContext} from "next";

export function redirectContextToStatus(res: NextPageContext["res"], status = 404) {
  if (res) {
    res.statusCode = status;
    res.writeHead(status)
  }
}

/** (Server side) auth yoksa anasayfa redirect et **/
export function authorizationRedirect(context: NextPageContext, authorization: AuthorizationType) {
  // @ts-ignore
  const store = context?.store?.getState?.();
  const res = context?.res;
  if (!store || !res) return;
  const isLoggedIn = store.authReducer.isLoggedIn;
  if ((authorization === "guest" && isLoggedIn) ||
    (authorization === "user" && !isLoggedIn)) {
    res.writeHead(307, {location: `/`});
    res.end();
  }
}

export function handleAfterLogin(payload: {ctx?: NextPageContext, token: string, refreshToken: string}) {
  const ctx = payload.ctx;
  setCookie(TOKEN_COOKIE_NAME, payload.token, {
    res: ctx?.res, req: ctx?.req,
    maxAge: 30 * 24 * 60 * 60,
    path: "/",
  });
  setCookie(REFRESH_COOKIE_NAME, payload.refreshToken, {
    res: ctx?.res, req: ctx?.req,
    maxAge: 30 * 24 * 60 * 60,
    path: "/",
  });
  if (isBrowser()) Router.reload();
}

export function handleAfterLogout(context?: NextPageContext) {
  const ctx = context;
  deleteCookie(TOKEN_COOKIE_NAME, {
    res: ctx?.res, req: ctx?.req,
    path: '/',
  });
  deleteCookie(REFRESH_COOKIE_NAME, {
    res: ctx?.res, req: ctx?.req,
    path: '/',
  });
  if (isBrowser()) Router.reload();
}
