import React, {useCallback, useEffect, useState} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import {useDispatch} from "react-redux";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {constantConfig} from "@/helper/config";
import {useForm} from "react-hook-form";
import {toast} from "react-hot-toast";
import {
    AppleIcon,
    CloseIcon,
    FacebookFIcon,
    GoogleIcon,
    LockIcon,
    UserIcon,
} from "../../../icons";
import Button from "../form/Button";
import {handleAfterLogin} from "@/helper/auth";
import {isUserExitsByProvider, login} from "@/services/auth";
import UserNameDialog from "@/components/dialogs/UserNameDialog";

type LoginDialogForm = {
    userName: string;
    password: string;
    provider?: 'google' | 'facebook';
    remember?: boolean;
};
const LoginDialog = ({open, onOpenChange}: { open: boolean, onOpenChange: (open: boolean) => void }) => {
    const [userNameDialogProps, setUserNameDialogProps] = useState<{ provider?: LoginDialogForm['provider'], password?: string }>({});
    const dispatch = useDispatch();
    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors, isSubmitting},
        reset,
    } = useForm<LoginDialogForm>();

    useEffect(() => {
        if (!open) {
            reset();
            setUserNameDialogProps({})
        }
    }, [open]);

    const doLogin = async ({userName, password, provider}: LoginDialogForm) => {
        const response = await login({
            userName,
            password,
            provider,
        });
        if (response.isSucceed) {
            onOpenChange(false);
            handleAfterLogin(response.data);
        } else {
            toast.error(response.message);
        }
    };

    const handleAfterProviderResponse = async ({password, provider}: { password: string, provider: 'google' | 'facebook' }) => {
        const response = await isUserExitsByProvider({
            token: password,
            provider,
        });
        // kullanici daha once register olduysa login yap, register olmadiysa username isteyen dialog'a yonlendir
        if (response.isSucceed) {
            if (response.data.userExists) {
                await doLogin({userName: '', password: response.data.accessToken, provider});
            } else {
                setUserNameDialogProps({password: response.data.accessToken, provider})
            }
        }
    }


    const googleResponse = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            handleAfterProviderResponse({password: tokenResponse.code, provider: 'google'});
        },
        flow: "auth-code",
    });

    const facebookResponse = async (response: any) => {
        handleAfterProviderResponse({password: response.token, provider: 'facebook'});
    };

    const onSubmit = async ({userName, password}: LoginDialogForm) => {
        await doLogin({
            userName,
            password,
        });
    };

    if (userNameDialogProps.password && userNameDialogProps.provider)
        return (
            <UserNameDialog
                open={true}
                onOpenChange={() => onOpenChange(false)}
                provider={userNameDialogProps.provider}
                password={userNameDialogProps.password}
            />
        );

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay"/>
                <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle mb-5">
                        <span className="text-highlight">Film ve dizi</span> dünyasına
                        hoşgeldin.
                    </Dialog.Title>
                    {renderForms()}
                    <div className="text-hr my-4">
                        <span/>
                        <span>ya da</span>
                        <span/>
                    </div>
                    <div className="d-flex gap-3 mb-4">
                        <Button block buttonType='outline' size='sm' onClick={googleResponse}>
                            <GoogleIcon fontSize={24}/>
                            Google
                        </Button>
                        {/*<FacebookLogin*/}
                        {/*    appId={constantConfig.FB_APP_ID as string}*/}
                        {/*    callback={facebookResponse}*/}
                        {/*    render={(renderProps) => (*/}
                        {/*        <Button block*/}
                        {/*                buttonType='outline'*/}
                        {/*                size='sm'*/}
                        {/*                onClick={renderProps.onClick}>*/}
                        {/*            <FacebookFIcon fontSize={24}/>*/}
                        {/*            Facebook*/}
                        {/*        </Button>*/}
                        {/*    )}*/}
                        {/*/>*/}
                        {/*<Button block buttonType='outline' size='sm'>*/}
                        {/*    <AppleIcon fontSize={24}/>*/}
                        {/*    Apple*/}
                        {/*</Button>*/}
                    </div>

                    <div className="text-center">
                        Henüz hesabın yok mu?{" "}
                        <a
                            className="text-brand"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                dispatch({
                                    type: "OPEN_MODAL",
                                    payload: {
                                        key: "register",
                                    },
                                });
                            }}
                        >
                            Kayıt ol
                        </a>
                    </div>

                    <Dialog.Close asChild>
                        <button className="dialog-close-btn" aria-label="Close">
                            <CloseIcon fontSize={24}/>
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );


    function renderForms() {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    className={"mb-3"}
                    icon={UserIcon}
                    label="Kullanıcı Adı"
                    disabled={isSubmitting}
                    error={errors.userName?.message}
                    {...register("userName", {
                        required: "Lütfen kullanıcı adınızı yazın.",
                    })}
                />
                <Input
                    className="mb-4"
                    icon={LockIcon}
                    type="password"
                    label="Şifre"
                    disabled={isSubmitting}
                    error={errors.password?.message}
                    {...register("password", {required: "Lütfen şifrenizi yazın."})}
                />
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <Checkbox
                        id="remember"
                        label={"Beni hatırla"}
                        disabled={isSubmitting}
                        {...register("remember")}
                    />
                    <a
                        className="text-decoration-underline forgot-pass-link"
                        onClick={() => {
                            dispatch({
                                type: "OPEN_MODAL",
                                payload: {
                                    key: "forgotPassword",
                                },
                            });
                        }}
                    >
                        Şifremi Unuttum
                    </a>
                </div>
                <Button
                    buttonType='primary'
                    size='md'
                    disabled={isSubmitting}
                    type="submit"
                    block
                >
                    Giriş yap
                </Button>
            </form>
        )
    }
};

export default LoginDialog;
