import React, {memo, useEffect, useState} from "react";
import {useRouter} from "next/router";
import {ToastPosition, Toaster} from "react-hot-toast";
import Header from "./Header";
import Footer from "./Footer";
import Filter from "./Filter";
import GoogleAd from "../components/GoogleAd";
import useScrollRestoration from "../hooks/useScrollRestoration";
import {useScroll, useMotionValueEvent} from "framer-motion";
import Dialogs from "./Dialogs";
import type {ReactNode} from 'react';
import {routes} from "@/helper/routes";
import {CheckCircleBrokenIcon, XCircleIcon} from "@/icons/index";
import {ToastBar} from "@/layout/ToastBar";
import useBreakpoint from "@/hooks/useBreakpoint";
import classNames from "classnames";

function AppContainer({children}: { children: ReactNode }) {
    const router = useRouter();
    const showFooterAd = [
        '/',
        '/[mediaType]',
        routes.roulette,
        routes.recommendedList.index,
        `${routes.recommendedList.index}/[listId]`,
        '/[mediaType]/[content]',
        `${routes.profile.lists}/[username]`,
        routes.search,
    ].includes(router.pathname);
    const showHeroAsDefault = ['/', '/[mediaType]/[content]'].includes(router.pathname);
    useScrollRestoration(router);

    return (
        <>
            <Dialogs/>
            <HeaderWrapper showHeroAsDefault={showHeroAsDefault}/>
            <div className={"app-container" + (showHeroAsDefault ? " app-container--home" : "")}>
                {children}
            </div>
            {showFooterAd &&
              <div className="googlead-footer">
                <GoogleAd slot={"footer"} format="inline"/>
              </div>
            }
            <Footer/>
            <ToasterWrapper/>
        </>
    );
}

const ToasterWrapper = () => {
  const isMobile = useBreakpoint(768);
  const toastPosition: ToastPosition = isMobile ? "bottom-center" : "top-center";

  return (
    <Toaster
      position={toastPosition}
      toastOptions={{
        success: {
          icon: <CheckCircleBrokenIcon fontSize={24} />,
        },
        error: {
          icon: <XCircleIcon fontSize={24} />,
        },
      }}
      containerStyle={{
        zIndex: 99999,
      }}
      children={(t) => ToastBar(t, toastPosition)}
    />
  );
};

const HeaderWrapper = memo(({showHeroAsDefault}: { showHeroAsDefault: boolean }) => {
    const router = useRouter();
    const showFilter =
        router.pathname === "/[mediaType]" &&
        router.query.mediaType &&
        // @ts-ignore
        ["film", "dizi", "icerikler"].includes(router.query.mediaType.toLowerCase());

    const [headerType, setHeaderType] = useState<'hero' | 'default'>(showHeroAsDefault ? 'hero' : 'default');
    const {scrollY} = useScroll({
        axis: "y",
    });


    useMotionValueEvent(scrollY, "change", (latest) => {
        if (showHeroAsDefault) {
            if (latest >= 50) {
                setHeaderType("default");
            } else {
                setHeaderType("hero");
            }
        }
    });

    useEffect(() => {
        if (showHeroAsDefault) {
            setHeaderType("hero");
        } else {
            setHeaderType("default");
        }
    }, [showHeroAsDefault]);

    return (
        <>
            <div className={classNames(headerType === "default" && "sticky-top")}>
                {(headerType === "default" || (showHeroAsDefault && headerType === "hero")) && <Header type={headerType}/>}
                {showFilter && <Filter/>}
            </div>
        </>
    )
})

export default memo(AppContainer);
