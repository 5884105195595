import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserListManagementDialog from "@/components/dialogs/UserListManagementDialog";
import {BookmarkIcon} from "@/icons/index";
import useContentInList from "@/hooks/useContentInList";

interface WatchListButtonsProps {
    type?: 'icon' | 'text';
    contentId: string;
}

function WatchListButtons({type, contentId}: WatchListButtonsProps) {
    const [openListDialog, setOpenListDialog] = useState(false);
    const {isInWatchList, isInSeenList} = useContentInList({
        contentId: contentId,
    });
    const dispatch = useDispatch();
    const {isLoggedIn} = useSelector((state: any) => state.authReducer);

    const openWatchListDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!isLoggedIn) {
            return dispatch({
                type: "OPEN_MODAL",
                payload: { key: "login" },
            });
        }
        setOpenListDialog(true);
    };

    const Icon = ({fontSize = 26}: { fontSize?: number }) => (
        <BookmarkIcon fontSize={fontSize} color={isInWatchList ? "#fdb62f" : "#fff"}/>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <UserListManagementDialog
                open={openListDialog}
                onOpenChange={(open) => setOpenListDialog(open)}
                defaultType="updateListItem"
                contentId={contentId}
            />
            {type === "text" ? renderText() : renderIcon()}
        </div>
    )

    function renderText() {
        return (
            <div className="d-flex hover-scale">
                {/*<div className='watchlist-button d-flex align-items-center mb-3'*/}
                {/*     data-on-list={seen.includes(item.id)}*/}
                {/*     data-page={page}*/}
                {/*     onClick={e => clickSeen(e, item)}>*/}
                {/*    <Eye/>*/}
                {/*    <p className='watchlist-button-title ms-2'>{seen.includes(item.id) ? 'Geri Al' : 'Bunu izledim, bir daha önerme'}</p>*/}
                {/*</div>*/}
                <div
                    className="watchlist-button d-flex align-items-center"
                    onClick={openWatchListDialog}
                    style={{cursor: 'pointer'}}
                >
                    <Icon fontSize={22}/>
                    <p className="watchlist-button-title ms-2" data-active={isInWatchList}>
                        {isInWatchList ? "İzleme listemden çıkar" : "İzleme listeme ekle"}
                    </p>
                </div>
            </div>
        )
    }

    function renderIcon() {
        return (
            <div className='watchlist-button-overlay-body hover-scale'  onClick={openWatchListDialog}>
                {/*<div className='watchlist-button-overlay'>*/}
                {/*    <div className='watchlist-button'*/}
                {/*         data-on-list={seen.includes(item.id)}*/}
                {/*         data-page={page}*/}
                {/*         onClick={e => clickSeen(e, item)}>*/}
                {/*        <Eye/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='watchlist-button-overlay'>
                    <Icon fontSize={26}/>
                </div>
            </div>
        );
    }
}

export default memo(WatchListButtons);
