// @ts-nocheck

import React, {memo, useState} from "react";
import HoverableContentPoster from "@/components/HoverableContentPoster";
import type {ContentTypeLight} from "@/types/contentType";
import {useKeenSlider} from "keen-slider/react";


function ContentListSlider({list, type}: { list: ContentTypeLight[], type?: 'recentView' | 'searchRecentView' }) {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        slides: {
            perView: 2.3,
            spacing: 12,
        },
        breakpoints: {
            "(min-width: 480px)": {
                slides: {
                    perView: type === 'recentView' ? 4.5 : 3.5,
                    spacing: 16
                },
            },
            "(min-width: 992px)": {
                slides: {
                    perView: type === 'recentView' ? 6 : type === "searchRecentView" ? 4.5 : 5,
                    spacing: type == "searchRecentView" ? 12 : 24
                },
            },
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    });

    const showArrows = list.length > instanceRef.current?.options?.slides?.perView;
    return (
        <div className="slider-navigation-wrapper" key={list[0].id}>
            <div ref={sliderRef} className="keen-slider slider-body">
                {list?.map?.((i) => (
                    <HoverableContentPoster forceViewType={type == "searchRecentView" ? "withCard" : undefined} className='keen-slider__slide' content={i} key={i.id}/>
                ))}
            </div>
            {showArrows && loaded && instanceRef.current && (
                <>
                    <PrevArrow
                        onClick={(e: any) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                    />

                    <NextArrow
                        onClick={(e: any) =>
                            e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                            currentSlide + instanceRef.current.options.slides.perView === list.length
                        }
                    />
                </>
            )}
        </div>
    );
}

function NextArrow(props: any) {
    const {disabled, onClick} = props;
    return (
        <div
            className='slider-next-arrow'
            data-disabled={disabled}
            onClick={onClick}
        >
            <img src="/icons/arrowDown.svg" alt="nerede izlenir arrow down" style={{transform: 'rotate(0.75turn)'}} width={20}/>
        </div>
    );
}

function PrevArrow(props: any) {
    const {disabled, onClick} = props;
    return (
        <div
            className='slider-prev-arrow'
            data-disabled={disabled}
            onClick={onClick}
        >
            <img src="/icons/arrowDown.svg" alt="nerede izlenir arrow down" style={{transform: 'rotate(0.25turn)'}} width={20}/>
        </div>
    );
}

export default memo(ContentListSlider);
