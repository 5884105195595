/**
 *Created By Barbaros Susuz on Apr, 2023
 **/
import React, {memo} from "react";
import type {ContentType, ContentTypeLight} from "@/types/contentType";


type RatesProps = {
    content: ContentType | ContentTypeLight;
    // default is true
    showLinks?: boolean;
    slice?: number;
    className?: string;
}
function Rates({
                   content,
                   showLinks = true,
                   slice,
                   className = "",
               }: RatesProps) {
    if (!content) return <></>;

    const rateEl = renderRates();
    if (!rateEl.length) return null;

    return <div className={`d-flex gap-2 ${className}`}>{rateEl}</div>;

    function renderRates() {
        let imgClass = "rating-img";
        let textClass = "rating-text";
        const bodyClass = "d-flex align-items-center flex-column gap-1";

        let rateObj = [
            {
                src: "/images/imdb.svg",
                key: "imdb",
                alt: "iMDB puanı",
            },
            {
                src: "/images/meta.svg",
                key: "metascore",
                alt: "MetaCritic puanı",
            },
            {
                src: "/images/rotten.svg",
                key: "rotten",
                alt: "Rotten Tomatoes puanı",
            },
            {
                src: "/images/tmdb-icon.svg",
                key: "tmdb",
                alt: "TMDB puanı",
            },
        ] as {
            [others: string]: any;
            key: "imdb" | "tmdb" | "rotten" | "metascore";
        }[];



        // add default nerede izlenir rate
        let arr: any[] = [];

        if (content.nizScore > 0)
            arr.push(
                <div className={bodyClass} key={'neredeizlenir'}>
                    <img className={imgClass} src='/images/Logo.svg' alt='Nerede izlenir puanı' title='Nerede izlenir puanı'/>
                    <span className={textClass}>{content.nizScore}</span>
                </div>
            )
        rateObj.forEach((i) => {
            if (content[i.key]?.rate > 0) {
                if (content[i.key].url && showLinks) {
                    let href = `${content[i.key].url}/?ref=neredeizlenir`;
                    arr.push(
                        <a
                            key={i.key}
                            className={bodyClass + ' hover-scale'}
                            href={href}
                            target="_blank"
                            rel="nofollow"
                        >
                            <img className={imgClass} src={i.src} alt={i.alt} title={i.alt}/>
                            <span className={textClass}>{content[i.key].rate}</span>
                        </a>
                    );
                } else {
                    arr.push(
                        <div key={i.key} className={bodyClass}>
                            <img className={imgClass} src={i.src} alt={i.alt} title={i.alt}/>
                            <span className={textClass}>{content[i.key].rate}</span>
                        </div>
                    );
                }
            }
        });

        // show only two rate in home
        return slice ? arr.slice(0, slice) : arr;
    }
}

export default memo(Rates);
