import React, {useEffect} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Input from "../form/Input";
import {useForm} from "react-hook-form";
import {toast} from "react-hot-toast";
import {CloseIcon, UserIcon,} from "../../../icons";
import Button from "../form/Button";
import {handleAfterLogin} from "@/helper/auth";
import {checkUserName, login} from "@/services/auth";

type LoginDialogForm = {
    userName: string;
};

type UserNameDialogProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    password: string;
    provider: 'google' | 'facebook';
}
const UserNameDialog = ({open, onOpenChange, provider, password}: UserNameDialogProps) => {
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting},
        reset,
    } = useForm<LoginDialogForm>();

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);


    const onSubmit = async ({userName}: LoginDialogForm) => {
        const response = await login({
            userName,
            password,
            provider,
        });
        if (response.isSucceed) {
            onOpenChange(false);
            handleAfterLogin(response.data);
        } else {
            toast.error(response.message);
        }

    };

    const checkUserNameExists = async (value: string) => {
        const response = await checkUserName(value);
        return response.isSucceed;
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay"/>
                <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle mb-5">
                        <span className="text-highlight">Film ve dizi</span> dünyasına
                        hoşgeldin.
                    </Dialog.Title>
                    <div className="mb-3 fw-semibold">
                        <span className='text-brand' style={{textTransform: 'capitalize'}}>{provider} </span> kaydın başarıyla tamamlandı, kullanıcı adı belirle.
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            className={"mb-3"}
                            icon={UserIcon}
                            label="Kullanıcı Adı"
                            disabled={isSubmitting}
                            error={errors.userName?.message}
                            {...register("userName", {
                                required: "Kullanıcı adı zorunludur",
                                maxLength: {value: 25, message: '25 karakterden uzun olamaz'},
                                minLength: {value: 3, message: '3 karakterden kisa olamaz'},
                                pattern: {value: /^[a-zA-Z0-9._]+$/, message: 'Kullanıcı adı özel karakter içeremez.'},
                                validate: async value => await checkUserNameExists(value) || "Bu kullanıcı adı zaten kullanılıyor",
                            })}
                        />
                        <Button
                            buttonType='primary'
                            size='md'
                            disabled={isSubmitting}
                            type="submit"
                            block
                        >
                            Kaydı tamamla
                        </Button>
                    </form>

                    <Dialog.Close asChild>
                        <button className="dialog-close-btn" aria-label="Close">
                            <CloseIcon fontSize={24}/>
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );

};

export default UserNameDialog;
