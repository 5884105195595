import React, {useState, useEffect} from "react";
import Link from "next/link";
import Image from "next/image";
import Search from "../components/Search";
import {useRouter} from "next/router";
import {motion, AnimatePresence} from "framer-motion";
import useBreakpoint from "../hooks/useBreakpoint";
import {useDispatch, useSelector} from "react-redux";
import * as Popover from "@radix-ui/react-popover";
import {routes} from "@/helper/routes";
import {
  BookmarkIcon, CanliTvIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExploreIcon,
  LogoutIcon,
  MessageIcon, RocketIcon,
  RouletteIcon,
  UserCircleIcon,
  UserIcon,
} from "../../icons";
import Button from "../components/form/Button";
import ProfileAvatar from "@/components/ProfileAvatar";
import {handleAfterLogout} from "@/helper/auth";

function Header({type = "default"}: { type: "hero" | "default" }) {
  const router = useRouter();
  const [searchOpen, setSearchOpen] = useState(false);
  const isMobile = useBreakpoint(992);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.authReducer.isLoggedIn);

  useEffect(() => {
    hideCollapse();
  }, [router.pathname])

  const hideCollapse = () => {
    const el = document.getElementById('navbarNav');
    if (el) el.className = 'collapse navbar-collapse flex-grow-0 custom-navbar-collapse'
  }
  return (
    <AnimatePresence>
      <motion.header
        id="header"
        className={"header--" + type}
        initial={{y: type === "hero" ? 50 : -50}}
        animate={{y: 0}}
        transition={{duration: 0.2}}
        exit={{y: -50, display: "none"}}
      >
        <nav
          className="navbar navbar-expand-lg container-fluid container-max-width navbar-custom d-flex justify-content-between"
          role="navigation"
          style={{gap: 5}}
        >
          <Link
            href={routes.home}
            className="navbar-brand d-flex align-items-center"
          >
            <Image
              src="/images/Logo.svg"
              alt="popcorn logo"
              width={40}
              height={40}
              unoptimized
            />
            {(!searchOpen || isMobile === false) && (
              <span className="ms-3">Nerede izlenir</span>
            )}
          </Link>
          {(searchOpen || isMobile === false) && <Search key={router.asPath}/>}
          <div className="">
            {!searchOpen && isMobile &&
              <button
                className="navbar-search-btn"
                type="button"
                onClick={() => setSearchOpen(!searchOpen)}
                aria-label="Toggle search"
              >
                <span className="navbar-search-btn-icon"/>
              </button>
            }
            <button
              className="navbar-toggler ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"/>
            </button>
          </div>
          <div
            className="collapse navbar-collapse flex-grow-0 custom-navbar-collapse"
            id="navbarNav"
            data-bs-theme="dark"
          >
            <ul className="navbar-nav">
              <li className="nav-item d-flex align-items-center">
                <Link href={routes.contents.index} className="nav-link">
                  <ExploreIcon fontSize={20}/>
                  İçerikler
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Link href={routes.liveTv.index} className="nav-link">
                  <CanliTvIcon fontSize={20}/>
                  Canlı TV
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Link href={routes.recommendedList.index} className="nav-link">
                  <RocketIcon fontSize={20}/>
                  Tavsiye Listeleri
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center me-2">
                <Link href={routes.roulette} className="nav-link">
                  <RouletteIcon fontSize={20}/>
                  Film Ruleti
                </Link>
              </li>
              {isLoggedIn ? (
                <li className="nav-item">
                  <UserMenu/>
                </li>
              ) : (
                <>
                  <Button
                    className={isMobile ? 'my-2' : 'me-3'}
                    size={isMobile ? 'lg' : 'md'}
                    buttonType={isMobile ? "unstyled" : "primary-outline"}
                    onClick={() => {
                      dispatch({
                        type: "OPEN_MODAL",
                        payload: {key: "login"},
                      });
                    }}
                  >
                    Giriş Yap
                  </Button>
                  <Button
                    size="lg"
                    buttonType="unstyled"
                    onClick={() => {
                      dispatch({
                        type: "OPEN_MODAL",
                        payload: {key: "register"},
                      });
                    }}
                  >
                    Kayıt Ol
                  </Button>
                </>
              )}
            </ul>
          </div>
        </nav>
      </motion.header>
    </AnimatePresence>
  );
}

function UserMenu() {
  const {userName, profilePictureUrl} = useSelector((state: any) => state.authReducer.user.userInformation);
  const logOut = () => {
    handleAfterLogout();
  };
  const isMobile = useBreakpoint(992);
  const [isOpen, setIsOpen] = useState(false);

  return isMobile ? renderMobile() : renderDesktop();

  function renderDesktop() {
    return (
      <Popover.Root>
        <Popover.Trigger asChild type="button">
          <Button size="md" buttonType="primary-outline">
            <ProfileAvatar
              url={profilePictureUrl}
              userName={userName}
              size={20}
            />
            Hesabım
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="PopoverContent" sideOffset={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Link className="user-menu__item" href={routes.profile.index}>
                <UserIcon fontSize={20}/>
                Profilim
              </Link>
              <Link className="user-menu__item" href={routes.profile.lists}>
                <BookmarkIcon fontSize={20}/>
                Listelerim
              </Link>
              <Link className="user-menu__item" href={routes.profile.comments}>
                <MessageIcon fontSize={20}/>
                Değerlendirmelerim
              </Link>
              <div className="user-menu__item" onClick={logOut}>
                <LogoutIcon fontSize={20}/>
                Çıkış Yap
              </div>
            </div>
            <Popover.Arrow className="PopoverArrow"/>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  function renderMobile() {
    return (
      <div className="nav-link w-100">
        <div className="w-100">
          <Button
            onClick={() => setIsOpen(!isOpen)}
            size="md"
            buttonType="unstyled"
            className={isOpen ? "text-brand" : undefined}
          >
            <ProfileAvatar
              url={profilePictureUrl}
              userName={userName}
              size={30}
            />
            Hesabım
            {isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
          </Button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{opacity: 0, y: -10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -10}}
                className="user-menu"
              >
                <div
                  className="ps-4 mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Link
                    className="user-menu__item text-white"
                    href={routes.profile.index}
                  >
                    <UserCircleIcon fontSize={24}/>
                    Profilim
                  </Link>
                  <Link
                    className="user-menu__item text-white"
                    href={routes.profile.lists}
                  >
                    <BookmarkIcon fontSize={24}/>
                    Listelerim
                  </Link>
                  <Link
                    className="user-menu__item text-white"
                    href={routes.profile.comments}
                  >
                    <MessageIcon fontSize={24}/>
                    Değerlendirmelerim
                  </Link>
                  <div className="user-menu__item" onClick={logOut}>
                    <LogoutIcon fontSize={20}/>
                    Çıkış Yap
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  }
}

export default Header;
