export const MEDIA_TYPE = {
  film: "movie",
  dizi: "show",
  icerikler: undefined,
};

export const SORT_LIST = {
  MetaScore: { tr: "Meta Puanı" },
  Imdb: { tr: "Imdb Puanı" },
  Rotten: { tr: "Rotten Puanı" },
  Tmdb: { tr: "Tmdb Puanı" },
  Popularity: { tr: "Popüler" },
};

export const NUMBER_LIST_DEFAULT = {
  year: {
    key: "year",
    title: "Yapım Yılı",
    step: 1,
    start: 1900,
    end: new Date().getFullYear(),
  },
  imdb: {
    key: "imdb",
    title: "IMDB Puanı",
    step: 0.1,
    start: 0,
    end: 10,
  },
  metascore: {
    key: "metascore",
    title: "MetaCritic",
    step: 1,
    start: 0,
    end: 100,
  },
  rotten: {
    key: "rotten",
    title: "RottenTomatos",
    step: 1,
    start: 0,
    end: 100,
  },
  tmdb: {
    key: "tmdb",
    title: "TMDB Puanı",
    step: 1,
    start: 0,
    end: 100,
  },
};

// TMDB image sizes
export const BACKDROP_SIZES = ["w300", "w780", "w1280", "original"];

export const TOKEN_COOKIE_NAME = "_t";
export const REFRESH_COOKIE_NAME = "_r";

export const AUTHORIZATION = {
  default: 'default',
  user: 'user',
  guest: 'guest'
} as const;

export type AuthorizationType = typeof AUTHORIZATION[keyof typeof AUTHORIZATION]

export const STORE_URLS = {
  android: 'https://play.google.com/store/apps/details?id=com.upapp.nerede_izlenir',
  ios: 'https://apps.apple.com/app/id1556727192'
}
