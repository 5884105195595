import React, {useCallback, useEffect, useState} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Input from "../form/Input";
import {useDispatch} from "react-redux";
import {CloseIcon, UserIcon} from "../../../icons";
import Button from "../form/Button";
import {forgotPassword} from "@/services/auth";

const ForgotPasswordDialog = ({open, onOpenChange}: { open: boolean, onOpenChange: (open: boolean) => void }) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sentUserName, setSentUserName] = useState('');

    useEffect(() => {
        if (!open) {
            setSentUserName('');
            setIsSubmitting(false);
        }
    }, [open]);

    const sendEmail = async (userName: string) => {
        setIsSubmitting(true);
        const response = await forgotPassword(userName);
        if (response.isSucceed) {
            setSentUserName(userName);
        }
        setIsSubmitting(false);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const userName = formData.get("userName");
        await sendEmail(userName as string);
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay"/>
                <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle mb-2">
                        {sentUserName ? "Şifreniz gönderildi" : "Şifre yenileme işlemi"}
                    </Dialog.Title>
                    {sentUserName ? (
                        <div>
                            <div className="mb-4">
                                E mail adresine şifre yenileme linkin gönderildi.
                            </div>
                            <div className="mb-3 fw-semibold">
                                Şifre Yenileme e-postası eline ulaşmadıysa:
                            </div>
                            <ul
                                className="mb-5"
                                style={{
                                    listStyle: "disc",
                                }}
                            >
                                <li>
                                    Şifre yenileme e-postasının gelmesi 5 dakikayı bulabilir.
                                </li>
                                <li>Spam ve diğer e-posta klasörlerinizi kontrol edin.</li>
                                <li>
                                    Girdiğin kullanıcı adınının doğruluğunu kontrol edin.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <>
                            <div className="mb-4">
                                Şifre yenileme bağlantısını gönderebilmemiz için kullanıcı adına
                                ihtiyacımız var.
                            </div>
                            <form onSubmit={handleSubmit}>
                                <Input
                                    name="userName"
                                    className="mb-3"
                                    icon={UserIcon}
                                    label="Kullanıcı Adı"
                                    type="text"
                                    disabled={isSubmitting}
                                    required
                                    title="Kullanıcı adı zorunludur"
                                />
                                <Button
                                    block
                                    size='md'
                                    buttonType='primary'
                                    className="mb-5"
                                    data-selected={true}
                                    disabled={isSubmitting}
                                    type='submit'
                                >
                                    Şifremi yenile
                                </Button>
                            </form>
                        </>
                    )}

                    <div className="text-center">
                        Henüz hesabın yok mu?{" "}
                        <a
                            className="text-brand"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                dispatch({
                                    type: "OPEN_MODAL",
                                    payload: {
                                        key: "register",
                                    },
                                });
                            }}
                        >
                            Kayıt ol
                        </a>
                    </div>

                    <Dialog.Close asChild>
                        <button className="dialog-close-btn" aria-label="Close">
                            <CloseIcon fontSize={24}/>
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default ForgotPasswordDialog;
