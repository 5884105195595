import React, {useEffect, useState} from 'react';
import Image from 'next/image';

// suan url gelmiyor icerideki image'lari basiyoruz
interface ProfileAvatarProps {
    url?: string;
    absolute?: boolean;
    userName: string;
    size?: number;
    className?: string;
    children?: React.ReactNode;
    style?: any;

    onClick?(e: any): void;
}

export default function ProfileAvatar({url, absolute, userName, size = 80, className = '', children, onClick, style}: ProfileAvatarProps) {
    const [imageError, setImageError] = useState(true);
    const handleImageError = () => {
        setImageError(true);
    };

    useEffect(() => {
        if (url === null || url === undefined) setImageError(true);
        else setImageError(false);
    }, [url]);

    return (
        <div className={`avatar-body ${className}`}
             style={{width: size, height: size, cursor: onClick ? 'pointer' : "unset", background:imageError ? "white" : "transparent", ...style}}
             onClick={onClick}
        >
            {imageError ? (
                // Render fallback image or alternative content
                <div className='avatar-fallback' style={{fontSize: size / 2.5}}>
                    {userName.charAt(0).toUpperCase()}
                </div>
            ) : (
                <Image
                    fill
                    unoptimized
                    className="avatar-image"
                    src={absolute ? url!  : `/images/profile/${url}.svg`}
                    alt="profile picture"
                    onError={handleImageError}
                />
            )}
            {children}
        </div>
    );
}
