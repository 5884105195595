import React, {useCallback, useEffect, useState} from "react";
import Link from "next/link";
import * as Dialog from "@radix-ui/react-dialog";
import {CloseIcon, PlusIcon} from "../../../icons";
import Button from "../form/Button";
import Input from "@/components/form/Input";
import {Controller, useForm} from "react-hook-form";
import toast from "@/helper/toast";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from 'lodash';
import Checkbox from "@/components/form/Checkbox";
import useContentInList from "@/hooks/useContentInList";
import type {ContentType} from "@/types/contentType";
import type {UserListType} from "@/types/listType";
import {createOrUpdateList, updateListItem} from "@/services/list";
import Textarea from "@/components/form/Textarea";

import {UserListTypeEnum} from "@/types/enum";


interface MutateUserListDialogProps {
  defaultType: 'createList' | 'updateListItem';
  open: boolean;
  contentId?: ContentType['id'];
  list?: UserListType,

  onOpenChange(open: boolean): void;
}

export default function UserListManagementDialog(props: MutateUserListDialogProps) {
  const {open, list, onOpenChange, defaultType = 'createList', contentId} = props;
  const [type, setType] = useState(defaultType);

  const isNew = !list?.id;

  useEffect(() => {
    if (!open) {
      setType(defaultType);
    }
  }, [open]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay"/>
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle mb-4">
            {type === 'createList' ? (isNew ? 'Yeni liste oluştur' : 'Listeni güncelle') : 'Listelerine kaydet'}
          </Dialog.Title>
          {type === 'createList' && open &&
            <CreateList
              list={list}
              contentId={contentId}
              onOpenChange={onOpenChange}
            />
          }
          {type === 'updateListItem' && open && contentId &&
            <AddListItem
              contentId={contentId}
              setType={defaultType => setType(defaultType)}
            />
          }
          <Dialog.Close asChild>
            <button className="dialog-close-btn" aria-label="Close">
              <CloseIcon fontSize={24}/>
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}


type CreateOrUpdateListProps = {
  list?: MutateUserListDialogProps['list'],
  onOpenChange: MutateUserListDialogProps['onOpenChange'],
  contentId: MutateUserListDialogProps['contentId']
}

function CreateList({onOpenChange, contentId, list}: CreateOrUpdateListProps) {
  const dispatch = useDispatch();
  const isNew = !list?.id;
  const {
    register,
    handleSubmit,
    control,
    formState: {errors, isSubmitting},
  } = useForm({
    defaultValues: {
      name: list?.name,
      description: list?.description,
      isPublic: list?.isPublic
    },
  });

  const onSubmitNewList = async ({name, description, isPublic}: any) => {
    const reqObj = {
      name,
      description,
      isPublic: isPublic,
      id: list?.id,
      type: UserListTypeEnum.Custom,
      items: contentId ? [contentId] : [],
    };
    // return;
    const response = await createOrUpdateList(reqObj);
    if (response.isSucceed) {
      // TODO apiden itemIds donmuyor diye boyle bir sey yapildi
      response.data.itemIds = response.data.items?.map(i => i.id) || [];
      dispatch({
        type: 'ADD_OR_UPDATE_USER_LIST',
        payload: response.data
      });
      if (isNew) {
        toast.success(`"${name}" listesi oluşturuldu.`);
      } else {
        toast.success(`"${name}" listesi güncellendi.`);
      }
      onOpenChange(false);
    } else {
      if (isNew) toast.error(`"${name}" listesi oluşturulamadı.`);
      else toast.error(`"${name}" listesi güncellenemedi.`);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitNewList)}>
        <Input
          className="mb-3"
          label="Liste ismi"
          disabled={isSubmitting}
          error={errors.name?.message}
          {...register("name", {
            maxLength: {value: 20, message: 'En fazla 20 karakter olabilir.'},
            required: "Lütfen liste ismini yazın."
          })}
        />
        <Textarea
          className="mb-4"
          label="Listen hakkında açıklama."
          disabled={isSubmitting}
          error={errors.description?.message}
          {...register("description", {
            maxLength: {value: 120, message: 'En fazla 120 karakter olabilir.'},
            required: "Lütfen liste açıklamasını yazın."
          })}
        />
        <Controller
          control={control}
          name="isPublic"
          render={({field}) => (
            <Checkbox
              {...field}
              id="isPublic"
              label="Listemi herkes görebilsin"
              error={errors.isPublic?.message}
              value={undefined}
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          )}
        />
        <Button
          className="mt-4 mb-3"
          disabled={isSubmitting}
          size='md'
          buttonType='primary'
          type="submit"
          block
        >
          {isNew ? 'Listeyi oluştur' : 'Listeyi güncelle'}
        </Button>
      </form>
    </>
  )
}

function AddListItem({contentId, setType}: { contentId: ContentType['id'], setType(defaultType: MutateUserListDialogProps['defaultType']): void }) {
  const dispatch = useDispatch();
  const {userLists} = useContentInList({contentId});
  const user = useSelector((state: any) => state.authReducer.user);

  const onAddList = useCallback(async (i: UserListType) => {
    if (!contentId) return;
    const cloneList = cloneDeep(userLists);
    const listIndex = userLists.findIndex(l => l.id === i.id);
    if (listIndex === -1) return;

    // liste icerisinde content varsa silecek yoksa ekleyecek
    const isExist = cloneList[listIndex].itemIds.includes(contentId);
    cloneList[listIndex].itemIds = isExist ?
      cloneList[listIndex].itemIds.filter(item => item !== contentId) : [...cloneList[listIndex].itemIds, contentId];
    dispatch({
      type: 'SET_USER_LIST',
      payload: cloneList
    });

    // istek cik
    const response = await updateListItem({listId: i.id, contentId: contentId});

    if (response.isSucceed) {
      if (isExist) {
        toast.success(
          <>
            İçerik <span className="fw-bold">{i.name}</span> koleksiyonundan
            çıkartıldı.
          </>
        );
      } else {
        toast.success(
          <>
            İçerik <span className="fw-bold">{i.name}</span> koleksiyonuna
            kaydedildi.
          </>,
          {
            action: `/liste/${user.userInformation.userName}?listId=${i.id}`,
            closeOnAction: true,
          }
        );
      }
    } else {
      // hata varsa degisikligi geri al.
      const cCloneList = cloneDeep(cloneList);
      cCloneList[listIndex].itemIds = !isExist ?
        cCloneList[listIndex].itemIds.filter(item => item !== contentId) : [...cCloneList[listIndex].itemIds, contentId];
      dispatch({
        type: 'SET_USER_LIST',
        payload: cCloneList
      });
      toast.error(isExist ? "İçerik çıkartılırken bir sorun oluştu." : `İçerik kaydedilirken bir sorun oluştu.`);
    }
  }, [userLists]);

  // dialog acildigi anda default watchlist'e eklenecek
  useEffect(() => {
    setTimeout(() => {
      const defaultList = userLists.find(i => i.type === UserListTypeEnum.Watch);
      if (defaultList && defaultList.itemIds?.length && !defaultList.itemIds.includes(contentId))
        onAddList(defaultList);
    }, 300)
  }, []);

  return (
    <>
      <div className="fw-bold mb-4">
        Eklemek istediğin listeyi aşağıdan seç veya yeni liste oluştur.
      </div>
      <div className='custom-scrollbar overflow-auto' style={{maxHeight: 380}}>
        {userLists.filter(i => i.type !== UserListTypeEnum.Seen).map(i =>
          <div
            key={i.id}
            className='selectable-user-list-item'
            onClick={() => onAddList(i)}
            data-selected={i.itemIds.includes(contentId)}
          >
            <p>{i.name}</p>
            <div className='d-flex gap-2'>
              <p>{i.itemIds.length} içerik</p>
              <Checkbox checked={i.itemIds.includes(contentId)}/>
            </div>
          </div>
        )}
      </div>
      <Button className='mt-4' block size='md' buttonType='primary' onClick={() => setType('createList')}>
        Yeni liste oluştur
        <PlusIcon fontSize={24}/>
      </Button>
    </>
  )
}


