export const endPoints = {
  moviesGetByFilter: "/Movies/GetByFilter",
  moviesGetByFilterDetail: "/Movies/GetByFilter/Detail",
  moviesGetDetail: "/Movies",
  getInitialConfig: "/Config/tr",
  moviesPopularTitle: "/Movies/PopularTitles",
  moviesRoulette: "/Movies/Roulette",
  moviesSearch: "/Movies/Search",
  getSearchPage: "/Movies/GetSearchPage",
  getPeopleById: "/Movies/GetPeopleById",
  getProductionById: "/Movies/GetProductionById",
  homePage: "/Movies/HomePage",
  auth: {
    login: "/Authorization/Login",
    register: "/Authorization/Register",
    refresh: "/Authorization/Refresh",
    checkUserName: "/Authorization/Check",
    emailVerify: "/Authorization/EmailVerify",
    forgotPassword: "/Authorization/Forgot",
    forgotPasswordVerify: "/Authorization/ForgotVerify",
    isUserExits: "/Authorization/IsUserExits",
    sendEmailVerification: "/User/SendEmailVerification",
    getMyUser: "/User",
    updateProfile: "/User/UpdateProfile",
    updateCommunicationPreferences: "/User/UpdateCommunicationPreferences",
    updateNotificationChannels: "/User/UpdateNotificationChannels",
    premium: "/User/Premium",
  },
  list: {
    list: "/List",
    createOrUpdateList: "/List",
    listItem: "/List/AddOrRemoveMovie",
  },
  comments: {
    postComment: "/Comments",
    movieComments: "/Comments/MovieComments",
    userMovieComments: "/Comments/UserMovieComments",
    voteComment: "/Comments/VoteComment",
    reportComment: "/Comments/ReportComment",
    removeComment: "/Comments/RemoveComment",
  },
  // local api
  local: {
    getRecommendList: "/api/get_recommend_list",
    getRecommendDetail: `/api/get_recommend_detail`,
    getLiveTv: "/api/get_live_tv",
    clearCache: `/api/clear`,
    getCachedConfig: `/api/getCachedConfig`,
    getCachedSearchPage: `/api/getCachedSearchPage`,
  },
};
