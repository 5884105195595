/**
 *Created By Barbaros Susuz on Jan, 2021
 **/

import {produce} from "immer";
import {GenderEnum} from "@/types/enum";
import type {UserType} from "@/types/core";
import type {UserListType} from "@/types/listType";

export const initialState = {
    isLoggedIn: false,
    user: {
        id: "",
        createdAt: 0,
        userInformation: {
            userName: "",
            profilePictureUrl: "",
        },
        email: "",
        accountStatus: 0,
        isExcludeFromResults: false,
        isPremium: false,
        profileInfo: {
            firstName: "",
            lastName: "",
            gender: GenderEnum.NotSpecified,
            birthDate: 0,
        },
        communicationInfo: {
            isNotificationApproved: false,
            isCampaignEmailApproved: false,
            agreementVersion: "",
            notificationChannels: [],
        },
        isEmailVerified: false,
        userLists: [],
    } as UserType,
};

const uiReducer = produce((draft = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case "SET_USER": {
            draft.isLoggedIn = true;
            draft.user = payload;
            return draft;
        }
        case "SET_USER_LIST": {
            draft.user.userLists = payload;
            return draft;
        }
        case "ADD_OR_UPDATE_USER_LIST": {
            const index = draft.user.userLists.findIndex((i: UserListType) => i.id === payload.id);
            if (index === -1) {
                draft.user.userLists.push(payload);
            } else {
                draft.user.userLists[index] = payload;
            }
            return draft;
        }
        case "DELETE_USER_LIST_BY_ID": {
            draft.user.userLists = draft.user.userLists.filter((i: UserListType) => i.id !== payload);
            return draft;
        }
        default: {
            return draft;
        }
    }
});

export default uiReducer;
