import React, {memo} from "react";
import Image, {type ImageProps} from "next/image";
import {isBrowser} from "@/helper/common";
import isMobile from "ismobilejs";
import type {ContentType, ContentTypeLight} from "@/types/contentType";
import type {CSSProperties} from "react";

export const TMDB_POSTER_SIZES = {
  w92: "w92",
  w154: "w154",
  w185: "w185",
  w342: "w342",
  w500: "w500",
  w780: "w780",
  w1280: "w1280",
  original: "original",
} as const;

type SizeProp = keyof typeof TMDB_POSTER_SIZES;

interface PosterProps extends Omit<ImageProps, "src" | "alt" | "content"> {
  content: ContentType | ContentTypeLight;
  size?: SizeProp;
}

const Poster = memo(
  ({content, className = "media-picture", size, ...rest}: PosterProps) => {
    if (!content) return <></>;
    const imageProps: ImageProps = {
      className,
      loader: () => "/images/placeholder.png",
      src: returnValidPoster(content.imageUrl, 'w500'),
      alt: `${content.title} ( ${content.originalTitle} )`,
      title: `${content.title} ( ${content.originalTitle} )`,
      fill: true,
      unoptimized: true,
    };
    if (size && TMDB_POSTER_SIZES[size])
      imageProps.src = returnValidPoster(content.imageUrl, TMDB_POSTER_SIZES[size]);

    return (
      <picture className={className}>
        <source
          type="image/jpg"
          srcSet={returnValidPoster(content.imageUrl, TMDB_POSTER_SIZES.w342)}
          media="(max-width: 475px)"
        />
        <source
          type="image/jpg"
          srcSet={returnValidPoster(content.imageUrl, TMDB_POSTER_SIZES.w500)}
          media="(max-width: 780px)"
        />
        <source
          type="image/jpg"
          srcSet={returnValidPoster(content.imageUrl, TMDB_POSTER_SIZES.w342)}
          media="(max-width: 1024px)"
        />
        <source
          type="image/jpg"
          srcSet={returnValidPoster(content.imageUrl, TMDB_POSTER_SIZES.w500)}
          media="(min-width: 1024px)"
        />
        <Image {...imageProps} {...rest}/>
      </picture>
    );
  }
);

export function returnValidPoster(imageUrl?: string, size?: SizeProp) {
  if (imageUrl === 'placeholder' || !imageUrl) return "/images/placeholder.png";
  else if (imageUrl.includes('image.tmdb.org')) return tmdbImageSizeChange(imageUrl, size);
  else return imageUrl;
}

export function tmdbImageSizeChange(imageUrl?: string, size?: SizeProp) {
  if (!imageUrl) return '';
  let mobileDevice = isBrowser() ? isMobile(window.navigator).phone : null;
  let posterSize = size
    ? size
    : mobileDevice
      ? TMDB_POSTER_SIZES.w342
      : TMDB_POSTER_SIZES.w1280;
  return imageUrl.replace("original", posterSize);
}

export default Poster;
