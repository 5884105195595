export { default as UserIcon } from "./svg/user-icon.svg";
export { default as UserCircleIcon } from "./svg/user-circle-icon.svg";
export { default as UserSquareIcon } from "./svg/user-square-icon.svg";
export { default as BellIcon } from "./svg/bell-icon.svg";
export { default as MessageIcon } from "./svg/message-icon.svg";
export { default as LockIcon } from "./svg/lock-icon.svg";
export { default as ChevronRightIcon } from "./svg/chevron-right-icon.svg";
export { default as ChevronUpIcon } from "./svg/chevron-up-icon.svg";
export { default as ChevronDownIcon } from "./svg/chevron-down-icon.svg";
export { default as GridIcon } from "./svg/grid-icon.svg";
export { default as BookmarkIcon } from "./svg/bookmark-icon.svg";
export { default as ClockFastIcon } from "./svg/clock-fast-icon.svg";
export { default as PremiumIcon } from "./svg/premium-icon.svg";
export { default as ArrowBackIcon } from "./svg/arrow-back-icon.svg";
export { default as RouletteIcon } from "./svg/roulette-icon.svg";
export { default as RouletteIcon2 } from "./svg/roulette-icon-2.svg";
export { default as StarIcon } from "./svg/star-icon.svg";
export { default as StarSolidIcon } from "./svg/star-icon-solid.svg";
export { default as LogoutIcon } from "./svg/log-out-icon.svg";
export { default as CloseIcon } from "./svg/close-icon.svg";
export { default as CheckboxCheckIcon } from "./svg/checkbox-check.svg";
export { default as GoogleIcon } from "./svg/google-icon.svg";
export { default as GoogleColorfulIcon } from "./svg/google-icon-colorful.svg";
export { default as FacebookFIcon } from "./svg/facebook-icon-f.svg";
export { default as AppleIcon } from "./svg/apple-icon.svg";
export { default as MailSolidIcon } from "./svg/mail-icon-solid.svg";
export { default as MailIcon } from "./svg/mail-icon.svg";
export { default as EyeOffIcon } from "./svg/eye-off-icon.svg";
export { default as EyeOnIcon } from "./svg/eye-on-icon.svg";
export { default as SearchIcon } from "./svg/search-icon.svg";
export { default as CalendarIcon } from "./svg/calendar-icon.svg";
export { default as PlusIcon } from "./svg/plus-icon.svg";
export { default as CommentIcon } from "./svg/comment-icon.svg";
export { default as ThumbsUpIcon } from "./svg/thumbs-up-icon.svg";
export { default as ThumbsUpColorfulIcon } from "./svg/thumbs-up-icon-colorful.svg";
export { default as DotsHorizontalIcon } from "./svg/dots-horizontal-icon.svg";
export { default as MessageAlertIcon } from "./svg/message-alert-icon.svg";
export { default as SortRoundedIcon } from "./svg/sort-rounded-icon.svg";
export { default as PopcornIcon } from "./svg/popcorn-icon.svg";
export { default as HeartIcon } from "./svg/heart-icon.svg";
export { default as ShareIcon } from "./svg/share-icon.svg";
export { default as CheckCircleBrokenIcon } from "./svg/check-circle-broken.svg";
export { default as XCircleIcon } from "./svg/x-circle.svg";
export { default as ReactIcon } from "./svg/react-icon.svg";
export { default as PlayIcon } from "./svg/play-icon.svg"
export { default as RocketIcon } from "./svg/rocket-icon.svg"
export { default as CanliTvIcon } from "./svg/canli-tv-icon.svg"
export { default as ExploreIcon } from "./svg/explore-icon.svg"
export { default as FilterIcon } from "./svg/filter.svg"

export { default as PandaIcon } from "./svg/panda-icon.svg"
