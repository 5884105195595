import {api, ApiProps} from "@/helper/apiHelper";
import {endPoints} from "@/helper/endPoints";
import {type RecommendedListType} from "@/types/listType";
import type {UserListType} from "@/types/listType";
import {UserListTypeEnum} from "@/types/enum";

export const getListById = async (id: string, ctx?: ApiProps['ctx']) => {
    return await api<UserListType>({url: `${endPoints.list.list}/${id}`, method: "GET", ctx});
};
export const deleteListById = async (id: string, ctx?: ApiProps['ctx']) => {
    return await api<UserListType>({url: `${endPoints.list.list}/${id}`, method: "DELETE", ctx});
};

export type CreateOrUpdateListRequest = {
    id?: string;
    items: string[];
    type: UserListTypeEnum;
    name: string;
    description: string;
    isPublic: boolean;
}
export const createOrUpdateList = async (data: CreateOrUpdateListRequest, ctx?: ApiProps['ctx']) => {
    return await api<UserListType>({url: endPoints.list.createOrUpdateList, method: "POST", data, ctx});
};
export const updateListItem = async (data: { listId: string, contentId: string  }, ctx?: ApiProps['ctx']) => {
    return await api({url: `${endPoints.list.listItem}/${data.listId}/${data.contentId}`, method: "GET", ctx});
};

/** LOCAL APIS **/
export const getRecommendList = async (ctx?: ApiProps['ctx']) => {
    return await api<RecommendedListType[]>({url: endPoints.local.getRecommendList, localApi: true, ctx});
};
export const getRecommendDetail = async (id: string, ctx?: ApiProps['ctx']) => {
    return await api<RecommendedListType>({
        url: endPoints.local.getRecommendDetail,
        data: {id},
        localApi: true,
        ctx
    });
};
export const clearListCache = async (type: string) => {
    return await api({
        url: endPoints.local.clearCache,
        data: {type},
        localApi: true,
    });
};
