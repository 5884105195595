import React, {useCallback, useEffect, useState} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import {useDispatch} from "react-redux";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {constantConfig} from "@/helper/config";
import {useForm, Controller} from "react-hook-form";
import {
    AppleIcon,
    CloseIcon,
    FacebookFIcon,
    GoogleColorfulIcon,
    LockIcon,
    MailIcon,
    MailSolidIcon,
    UserIcon,
} from "../../../icons";
import {toast} from "react-hot-toast";
import Button from "../form/Button";
import {handleAfterLogin} from "@/helper/auth";
import {checkUserName, isUserExitsByProvider, login, register as registerRequest} from "@/services/auth";
import UserNameDialog from "@/components/dialogs/UserNameDialog";

type RegisterDialogForm = {
    userName: string;
    password: string;
    passwordRepeat?: string;
    provider?: 'google' | 'facebook';
    email?: string;
    // "accept-terms"?: boolean;
}
const RegisterDialog = ({open, onOpenChange}: { open: boolean, onOpenChange: (open: boolean) => void }) => {
    const [showForm, setShowForm] = useState(false);
    const [userNameDialogProps, setUserNameDialogProps] = useState<{ provider?: RegisterDialogForm['provider'], password?: string }>({});
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isSubmitting},
        getValues,
        control,
        reset,
    } = useForm<RegisterDialogForm>();


    useEffect(() => {
        if (!open) {
            reset();
            setUserNameDialogProps({})
            setShowForm(false)
        }
    }, [open]);

    const handleAfterProviderResponse = async ({password, provider}: { password: string, provider: 'google' | 'facebook' }) => {
        const response = await isUserExitsByProvider({
            token: password,
            provider,
        });
        // kullanici daha once register olduysa login yap, register olmadiysa username isteyen dialog'a yonlendir
        if (response.isSucceed) {
            if (response.data.userExists) {
                const loginResponse = await login({
                    password: response.data.accessToken,
                    provider,
                });
                if (loginResponse.isSucceed) {
                    onOpenChange(false);
                    handleAfterLogin(loginResponse.data);
                } else {
                    toast.error(response.message);
                }
            } else {
                setUserNameDialogProps({password: response.data.accessToken, provider})

            }
        }
    }

    const googleResponse = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            handleAfterProviderResponse({password: tokenResponse.code, provider: 'google'});
        },
        flow: "auth-code",
    });

    const facebookResponse = async (response: any) => {
        handleAfterProviderResponse({password: response.token, provider: 'facebook'});
    };

    const onSubmit = async (data: RegisterDialogForm) => {
        const {userName, email, password, provider} = data;
        const response = await registerRequest({
            userName,
            password,
            provider,
            email,
        });
        if (response.isSucceed) {
            onOpenChange(false);
            handleAfterLogin(response.data);
        } else {
            toast.error(response.message);
        }
    };

    const checkUserNameExists = async (value: string) => {
        const response = await checkUserName(value);
        return response.isSucceed;
    };

    if (userNameDialogProps.password && userNameDialogProps.provider)
        return (
            <UserNameDialog
                open={true}
                onOpenChange={() => onOpenChange(false)}
                provider={userNameDialogProps.provider}
                password={userNameDialogProps.password}
            />
        );

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay"/>
                <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle mb-5">
                        <span className="text-highlight">Film ve dizi</span> dünyasına katıl
                        ve eğlenceye başla.
                    </Dialog.Title>
                    {!showForm && (
                        <>
                            <Button
                                block
                                size='md'
                                buttonType='white'
                                className='mb-3 justify-content-start'
                                onClick={googleResponse}
                            >
                                <GoogleColorfulIcon fontSize={24}/>
                                Google ile kayıt ol
                            </Button>
                            {/*<FacebookLogin*/}
                            {/*    appId={constantConfig.FB_APP_ID as string}*/}
                            {/*    callback={facebookResponse}*/}
                            {/*    render={(renderProps) => (*/}
                            {/*        <Button*/}
                            {/*            block*/}
                            {/*            size='md'*/}
                            {/*            buttonType='white'*/}
                            {/*            className='mb-3 justify-content-start'*/}
                            {/*            onClick={renderProps.onClick}*/}

                            {/*        >*/}
                            {/*            <FacebookFIcon fontSize={24}/>*/}
                            {/*            Facebook ile kayıt ol*/}
                            {/*        </Button>*/}
                            {/*    )}*/}
                            {/*/>*/}
                            {/*<Button*/}
                            {/*    block*/}
                            {/*    size='md'*/}
                            {/*    buttonType='white'*/}
                            {/*    className='mb-3 justify-content-start'*/}
                            {/*>*/}
                            {/*    <AppleIcon fontSize={24}/>*/}
                            {/*    Apple ile kayıt ol*/}
                            {/*</Button>*/}
                            <Button
                                block
                                size='md'
                                buttonType='primary'
                                className='mb-3 justify-content-start'
                                data-selected="true"
                                onClick={() => setShowForm(true)}
                            >
                                <MailSolidIcon fontSize={24}/>
                                E-posta ile devam et
                            </Button>
                        </>
                    )}
                    {renderForms()}
                    <div className="text-center mt-5">
                        Zaten hesabın var mıydı?{" "}
                        <a
                            className="text-brand"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                dispatch({
                                    type: "OPEN_MODAL",
                                    payload: {
                                        key: "login",
                                    },
                                });
                            }}
                        >
                            Giriş Yap
                        </a>
                    </div>
                    <Dialog.Close asChild>
                        <button className="dialog-close-btn" aria-label="Close">
                            <CloseIcon fontSize={24}/>
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );

    function renderForms() {
        if (!showForm) return <></>;
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    className={"mb-3"}
                    icon={UserIcon}
                    label="Kullanıcı Adı"
                    disabled={isSubmitting}
                    error={errors.userName?.message}
                    {...register("userName", {
                        required: "Kullanıcı adı zorunludur",
                        maxLength: {value: 25, message: '25 karakterden uzun olamaz'},
                        minLength: {value: 3, message: '3 karakterden kisa olamaz'},
                        pattern: {value: /^[a-zA-Z0-9._]+$/, message: 'Kullanıcı adı özel karakter içeremez.'},
                        validate: async value => await checkUserNameExists(value) || "Bu kullanıcı adı zaten kullanılıyor",
                    })}
                />

                <Input
                    className={"mb-3"}
                    icon={MailIcon}
                    label="E-posta adresi"
                    type="email"
                    disabled={isSubmitting}
                    error={errors.email?.message}
                    {...register("email", {
                        required: "E-posta adresi zorunludur",
                    })}
                />
                <Input
                    className="mb-3"
                    icon={LockIcon}
                    type="password"
                    label="Şifre"
                    disabled={isSubmitting}
                    error={errors.password?.message}
                    {...register("password", {
                        required: "Şifre zorunludur",
                    })}
                />
                <Input
                    className="mb-4"
                    icon={LockIcon}
                    type="password"
                    label="Şifreyi onayla"
                    disabled={isSubmitting}
                    error={errors.passwordRepeat?.message}
                    {...register("passwordRepeat", {
                        required: "Şifrenizi tekrar girin.",
                        validate: (value) =>
                            value === getValues("password") || "Şifreler eşleşmiyor.",
                    })}
                />
                {/*<div className="d-flex justify-content-between align-items-center mb-4">*/}
                {/*  <Controller*/}
                {/*    control={control}*/}
                {/*    name="accept-terms"*/}
                {/*    rules={{*/}
                {/*        required: "Kullanım koşullarını kabul etmelisiniz",*/}
                {/*    }}*/}
                {/*    render={({field}) => (*/}
                {/*        <Checkbox*/}
                {/*            {...field}*/}
                {/*            id="accept-terms"*/}
                {/*            label={"Kullanım koşullarını kabul ediyorum"}*/}
                {/*            error={errors["accept-terms"]?.message}*/}
                {/*            value={undefined}*/}
                {/*            checked={field.value}*/}
                {/*            onCheckedChange={field.onChange}*/}
                {/*            disabled={isSubmitting}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*</div>*/}
                <Button
                    block
                    size='md'
                    buttonType='primary'
                    disabled={isSubmitting}
                    type='submit'
                >
                    Kayıt ol
                </Button>
            </form>
        )
    }
};

export default RegisterDialog;
