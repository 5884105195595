import React, {memo} from "react";
import {tmdbImageSizeChange} from "@/components/Poster";
import {useKeenSlider} from "keen-slider/react";
import CompanyLogo from "@/components/content/CompanyLogo";

import type {ProductionCompanyType} from "@/types/contentType";
import {routes} from "@/helper/routes";
import Link from "next/link";

function CompanyListSlider({list = []}: { list: ProductionCompanyType[] }) {
  const sortedList = [...list]
  sortedList.sort((a, b) => {
    if (!a.logoPath) {
      return 1;
    } else if (!b.logoPath) {
      return -1;
    } else {
      return 0
    }
  });
  const [companiesSliderRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      spacing: 8,
      perView: "auto",
    },
  });

  return (
    <div
      ref={companiesSliderRef}
      className="keen-slider"
    >
      {sortedList.map((productionCompany) => (
        <Link
          key={productionCompany.id}
          href={{
            pathname: routes.production,
            query: {
              id: productionCompany.id,
            },
          }}
        >
          <div
            className="keen-slider__slide company-list-item"
          >
            <div className="company-list-imagewrapper">
              <CompanyLogo
                companyName={productionCompany.name}
                url={tmdbImageSizeChange(productionCompany.logoPath, "w154")}
                className="company-list-image"
              />
            </div>
            <p className="company-list-name">{productionCompany.name}</p>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default memo(CompanyListSlider);
