import {memo, useEffect} from "react";
import type {ReactNode, CSSProperties} from 'react';
import isBot from "@/helper/isBot";

const adTypes = {
    footer: 4439769236,
    sideLeft: 9713103174,
    sideRight: 6900071723,
    iceriklerTop: 8475170980,
    betweenList: 3393454443,
    tavsiyeListesiCard: 1578418042,
    home1: 8428829055,
    home2: 4129711147,
    contentDetail: 4105343187,
};

export interface GoogleAdProps {
    client?: string;
    className?: string;
    style?: CSSProperties;
    slot: keyof typeof adTypes;
    layout?: string;
    layoutKey?: string,
    format?: 'auto' | any;
    responsive?: boolean | string;
    pageLevelAds?: boolean;
    adTest?: any;
    children?: ReactNode;
}

function GoogleAd({
                      client = 'ca-pub-8700588705944035',
                      className = '',
                      style,
                      slot,
                      layout = '',
                      layoutKey = '',
                      format = 'auto',
                      responsive = 'true',
                      pageLevelAds = false,
                      adTest,
                      children,
                      ...rest
                  }: GoogleAdProps) {

    useEffect(() => {
        const p: any = {};
        if (pageLevelAds) {
            p.google_ad_client = client;
            p.enable_page_level_ads = true;
        }
        try {
            if (typeof window === 'object') {
                ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(p);
            }
        } catch {}
    }, []);

    if (isBot()) return null;

    return (
        <ins
            className={`adsbygoogle ${className}`}
            style={style}
            data-ad-client={client}
            data-ad-slot={adTypes[slot]}
            data-ad-layout={layout}
            data-ad-layout-key={layoutKey}
            data-ad-format={format}
            data-full-width-responsive={responsive}
            data-adtest={adTest}
            {...rest}
        >
            {children}
        </ins>
    );

}

export default memo(GoogleAd);
