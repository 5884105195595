import React, {memo, useCallback, useState} from 'react';
import Image from 'next/image'
import Link from "next/link";
import {useDispatch, useSelector} from 'react-redux'
import NumbersFilterBox from "../components/filter/NumbersFilterBox";
import GenreSelectionBox from "../components/filter/GenreSelectionBox";
import SortSelectionBox from "../components/filter/SortSelectionBox";
import useFilterQueryParams from "../hooks/useFilterQueryParams";
import Router from "next/router";
import {omit} from "lodash";
import {convertQueryObjectToQueryString} from "@/helper/common";
import {CloseIcon, FilterIcon} from "@/icons/index";
import Button from "@/components/form/Button";
import * as Dialog from "@radix-ui/react-dialog";
import type {StreamListType} from "@/types/dataType";
import CountrySelectionBox from "@/components/filter/CountrySelectionBox";

function Filter() {
  const [queryWithDefaults, setQuery, query] = useFilterQueryParams({streams: [], genres: [], mediaType: 'icerikler', country: []});
  const streamList: StreamListType[] = useSelector((state: any) => state.generalReducer.streamList);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  // @ts-ignore
  const {mediaType, streams, genres, sortBy, country} = queryWithDefaults;

  const resetFilter = () => {
    Router.replace('/icerikler', undefined, {shallow: true});
    dispatch({
      type: 'RESET_FILTER'
    });
  };

  const clickStream = useCallback((id: StreamListType['id']) => {
    let currentStreams = [...streams];
    let selectedCount = streams.length;
    // if all selected
    if (selectedCount === 0 || selectedCount === streamList.length) {
      currentStreams = [];
      currentStreams.push(id);
    }
    // if only one selected and undo selected one
    else if (selectedCount === 1 && currentStreams.includes(id.toString())) {
      currentStreams = [];
    }
    // select all one by one
    else if (selectedCount === streamList.length - 1 && !currentStreams.includes(id.toString())) {
      currentStreams = [];
    }
    // selected count bigger then 0 also lesser then stream list
    else if (selectedCount > 0 && selectedCount < streamList.length) {
      const index = currentStreams.indexOf(id.toString());
      if (index > -1) {
        currentStreams.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        currentStreams.push(id);
      }
    }
    setQuery('streams', currentStreams);
  }, [streams]);

  const queryStringWithoutType = convertQueryObjectToQueryString(omit(query, 'mediaType'))

  return (
    <>
      {filterDialog()}
      <div className='filter-bar py-3'>
        <div className='container-fluid container-max-width'>
          <div className='d-flex overflow-auto hide-scroll-bar'>
            {streamList?.map(i =>
              <Image
                className='platform-icon'
                key={i.id}
                src={i.imageUrl}
                alt={i.name}
                title={i.name}
                data-active={streams.length === 0 || streams.includes(i.id.toString())}
                onClick={() => clickStream(i.id)}
                width={50}
                height={50}
                // unoptimized={true}
              />
            )}
          </div>
          <div className='d-flex mt-3 flex-column flex-lg-row'>
            <div className='d-flex gap-2 w-100'>
              <Link
                href={queryStringWithoutType ? `/icerikler?${queryStringWithoutType}` : `/icerikler`}
                shallow
                replace
                className='black-button'
                data-selected={mediaType.toLowerCase() === 'icerikler'}
              >
                Hepsi
              </Link>
              <Link
                href={queryStringWithoutType ? `/film?${queryStringWithoutType}` : `/film`}
                shallow
                replace
                className='black-button'
                data-selected={mediaType.toLowerCase() === 'film'}
              >
                Film
              </Link>
              <Link
                href={queryStringWithoutType ? `/dizi?${queryStringWithoutType}` : `/dizi`}
                shallow
                replace
                className='black-button'
                data-selected={mediaType.toLowerCase() === 'dizi'}
              >
                Dizi
              </Link>
              <hr className='vertical-hr mx-auto'/>
              <Button buttonType='black' className='d-md-none ms-auto' onClick={() => setDialogOpen(true)}>
                Filtrele
                <FilterIcon width={15} color='white'/>
              </Button>
              <div className='d-none d-md-flex gap-2 w-100'>
                <NumbersFilterBox/>
                <CountrySelectionBox
                  selectedCountries={country}
                  onChange={value => {
                    const index = country.indexOf(value);
                    if (index > -1) {
                      setQuery('country', country.filter((i: string) => i !== value));
                    } else {
                      setQuery('country', country.concat(value));
                    }
                  }}
                />
                <GenreSelectionBox
                  selectedGenres={genres}
                  onChange={value => {
                    const index = genres.indexOf(value);
                    if (index > -1) {
                      setQuery('genres', genres.filter((i: string) => i !== value));
                    } else {
                      setQuery('genres', genres.concat(value));
                    }
                  }}
                />
                <Button className='ms-1' buttonType='unstyled' onClick={resetFilter}>
                  Filtre Temizle
                  <CloseIcon fontSize={15}/>
                </Button>
                <div className='ms-auto'>
                  <SortSelectionBox
                    sortBy={sortBy}
                    onChange={value => {
                      if (value === 'Popularity') {
                        setQuery('sortBy', undefined);
                      } else {
                        setQuery('sortBy', value);

                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );


  function filterDialog() {
    return (
      <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay"/>
          <Dialog.Content className="DialogContent filter-dialog-content">
            <Dialog.Title className="DialogTitle mb-4">
              İçerikleri Filtrele
            </Dialog.Title>
            <div className='d-flex gap-2 mb-3'>
              <GenreSelectionBox
                selectedGenres={genres}
                onChange={value => {
                  const index = genres.indexOf(value);
                  if (index > -1) {
                    setQuery('genres', genres.filter((i: string) => i !== value));
                  } else {
                    setQuery('genres', genres.concat(value));
                  }
                }}
              />
              <CountrySelectionBox
                selectedCountries={country}
                onChange={value => {
                  const index = country.indexOf(value);
                  if (index > -1) {
                    setQuery('country', country.filter((i: string) => i !== value));
                  } else {
                    setQuery('country', country.concat(value));
                  }
                }}
              />
              <SortSelectionBox
                sortBy={sortBy}
                onChange={value => {
                  if (value === 'Popularity') {
                    setQuery('sortBy', undefined);
                  } else {
                    setQuery('sortBy', value);

                  }
                }}
              />
            </div>

            <div className='d-flex flex-column align-items-center mt-3'>
              <NumbersFilterBox withoutPopup={true}/>
            </div>
            <Button className='mt-3 mx-auto' buttonType='outline' onClick={resetFilter}>
              Filtre Temizle
              <CloseIcon fontSize={15}/>
            </Button>
            <Dialog.Close asChild>
              <button className="dialog-close-btn" aria-label="Close">
                <CloseIcon fontSize={24}/>
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
}


export default memo(Filter);
