import React, {memo} from "react";
import Poster from "./Poster";
import WatchListButtons from "./content/WatchListButtons";
import Rates from "./Rates";
import Link from "next/link";
import useBreakpoint from "@/hooks/useBreakpoint";
import type {ContentTypeLight} from "@/types/contentType";

function HoverableContentPoster(props: { className?: string; content: ContentTypeLight, forceViewType?: 'hover' | 'withCard' }) {
    const {className, content, forceViewType} = props;
    const url = `/${content.slugUrl}`;
    const isMobile = useBreakpoint(992);

    return (
        <Link href={url} className={`hoverable-content-poster ${className}`}>
            <div className="media-picture-wrapper aspect-ratio-10_15">
                <Poster content={content} size='w342' style={isMobile ? {borderRadius: '12px 12px 0 0'} : {}}/>
            </div>
            {renderHoverView()}
            {renderWithCardView()}
        </Link>
    );

    function renderHoverView() {
        if (forceViewType === 'withCard') return;
        if (!forceViewType && isMobile) return;
        return (
            <div className='content-poster-info'>
                <div className='d-flex flex-column justify-content-between h-100'>
                    <WatchListButtons contentId={content.id} type='icon'/>
                    <Rates content={content} className='mt-auto mb-3 mx-auto' slice={2} showLinks={false}/>
                    <div>
                        <p className='media-title mb-1'>{content.title || content.originalTitle} ({content.year})</p>
                        <div className='genre-text'>
                            {content.genres.slice(0, 3).join(', ')}
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    function renderWithCardView() {
        if (forceViewType === 'hover') return;
        if (!forceViewType && !isMobile) return;
        return (
            <div className='content-poster-info-mobile'>
                <WatchListButtons contentId={content.id} type='icon'/>
                <div>
                    <p className='media-title mb-1'>{content.title || content.originalTitle} ({content.year})</p>
                    <div className='genre-text'>
                        {content.genres.slice(0, 3).join(', ')}
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(HoverableContentPoster);
