export enum AccountStatusEnum {
    WaitingApproval = 0,
    Approved = 1,
    Blocked = 2
}

export enum GenderEnum {
    NotSpecified = 'NotSpecified',
    Male = 'Male',
    Female = 'Female'
}

export enum UserReactionEnum {
    UpVote = "UpVote",
    DownVote = "DownVote",
    Neutral = "Neutral"
}

export enum CommentRequestEnum {
    Movie = 0,
    List = 1,
    SportMatch = 2
}

export enum UserListTypeEnum {
    Watch = "Watch",
    Seen = "Seen",
    Custom = "Custom",
}
