/**
 *Created By Barbaros Susuz on Jan, 2021
 **/
import { combineReducers } from "redux";

import generalReducer from "./generalReducer";
import uiReducer from "./uiReducer";
import authReducer from "./authReducer";

export default combineReducers({
  generalReducer,
  uiReducer,
  authReducer,
});
