import { useState, useEffect } from "react";

const useRenderInBrowser = () => {
    const [renderingInBrowser, setRenderingInBrowser] = useState(false);

    useEffect(() => {
        setRenderingInBrowser(true);
    }, []);

    return renderingInBrowser;
};

export default useRenderInBrowser;
