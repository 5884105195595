import React, {memo} from 'react';
import Image from "next/image";
import {SORT_LIST} from "@/helper/constants";
import {useSelector} from 'react-redux';
import * as Popover from "@radix-ui/react-popover";
import Button from "@/components/form/Button";
interface SortSelectionBoxProps {
    sortBy: keyof typeof SORT_LIST;
    onChange: (sort: keyof typeof SORT_LIST) => void;
}
function SortSelectionBox({sortBy = 'Popularity', onChange}: SortSelectionBoxProps){
    const sortList: SortSelectionBoxProps['sortBy'][] = useSelector((state: any) => state.generalReducer.sortList);

    return (
        <Popover.Root>
            <Popover.Trigger asChild type='button'>
                <Button buttonType='black'>
                    <div className='d-flex'><div style={{width: 80, textAlign: 'center'}}>{SORT_LIST[sortBy].tr}</div></div>
                    <Image className='ms-auto' src="/icons/sort-icon.svg" alt="sort icon" width={15} height={15}/>
                </Button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content className="PopoverContent" sideOffset={5} style={{width: 230}}>
                    <div>
                        {sortList.map((s) =>
                            <div className='sort-selection-item' key={s} data-selected={sortBy === s} onClick={() => changeSort(s)}>
                                {SORT_LIST[s].tr}
                                {/*<Image src="/icons/up-down-filled-arrow.svg" alt="up down filled arrow" width={15} height={15}/>*/}
                            </div>
                        )}
                    </div>
                    <Popover.Arrow className="PopoverArrow"/>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )

    function changeSort(value: keyof typeof SORT_LIST) {
        onChange(value);
    }
}

export default memo(SortSelectionBox);
