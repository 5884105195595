import React, {forwardRef, useMemo} from "react";
import type {ButtonHTMLAttributes, ForwardedRef} from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    block?: boolean;
    size?: 'sm' | 'md' | 'lg';
    buttonType: 'primary' | 'primary-outline' | 'outline' | 'unstyled' | 'white' | 'black';
}

function Button(props: ButtonProps, ref?: ForwardedRef<HTMLButtonElement>) {
    const {
        className,
        buttonType,
        disabled,
        size,
        block,
        style,
        type = 'button',
        ...restProps
    } = props;
    const mutatedClassName = useMemo(() => {
        let cs = 'custom-btn';
        if (size === 'md') cs += ' custom-btn--md';
        if (size === 'lg') cs += ' custom-btn--lg';

        if (buttonType === 'primary') cs += ' primary-btn';
        if (buttonType === 'primary-outline') cs += ' primary-outline-btn';
        if (buttonType === 'outline') cs += ' outline-btn';
        if (buttonType === 'unstyled') cs += ' unstyled-btn';
        if (buttonType === 'white') cs += ' white-btn';
        if (buttonType === 'black') cs += ' black-btn';

        if (block) cs += ' w-100';
        if (className) cs += ` ${className}`;
        return cs;
    }, [buttonType, size, block, className])

    return (
        <button
            ref={ref}
            className={mutatedClassName}
            style={style}
            disabled={disabled}
            type={type}
            {...restProps}
        />
    )
}

export default forwardRef(Button);
