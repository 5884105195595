/**
 *Created By Barbaros Susuz on Feb, 2021
 **/

import firebase from "firebase/app";
import "firebase/analytics";
import {isDevelopment} from "@/helper/common";

export let analytics;
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDOFqlDYGLa8iIRfEIFNavGaxD515028ik",
    authDomain: "nerede-izlenir-4c60d.firebaseapp.com",
    projectId: "nerede-izlenir-4c60d",
    storageBucket: "nerede-izlenir-4c60d.appspot.com",
    messagingSenderId: "339515923195",
    appId: "1:339515923195:web:c0f5f45d1f343b24cb2e5e",
    databaseURL: "https://nerede-izlenir-4c60d-default-rtdb.europe-west1.firebasedatabase.app",
    measurementId: "G-PT3MQ0GPVF"
};

// Initialize Firebase
export async function initializeFirebase() {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
        analytics = firebase.analytics();
    }
}

export function sendEvent(eventName, eventProps) {
    if (!isDevelopment())
        analytics.logEvent(eventName, eventProps)
}
