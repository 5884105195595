import NProgress from "nprogress";
import {isBrowser} from "./common";
import {SignJWT} from "jose";
import {constantConfig} from "./config";
import {getCookies, setCookie, deleteCookie} from "cookies-next";
import {REFRESH_COOKIE_NAME, TOKEN_COOKIE_NAME} from "./constants";
import {endPoints} from "./endPoints";
import type {NextPageContext} from "next";
import {BaseApiResponse} from "@/types/apiType";
import absoluteUrl from "@/helper/absoluteUrl";
import {refresh} from "@/services/auth";

export interface ApiProps {
    url: string;
    method?: "POST" | "GET" | "DELETE";
    data?: any;
    progress?: boolean;
    localApi?: boolean;
    additionalHeaders?: object;
    ctx?: NextPageContext;
}

interface ApiFetchProps extends ApiProps {
    noAuth?: boolean;
}

export async function api<T>(props: ApiProps): Promise<BaseApiResponse<T>> {
    const {progress} = props;
    if (isBrowser() && progress) NProgress.start();
    return apiFetch<T>(props)
        .then(function (response: BaseApiResponse<T>) {
            if (isBrowser() && progress) NProgress.done();
            return response;
        })
        .catch((error: any) => {
            console.error("api catch:", props.url, error.message);
            return {isSucceed: false, message: error.message, data: null as T};
        });
}

async function apiFetch<T>(props: ApiFetchProps): Promise<BaseApiResponse<T>> {
    const {
        url,
        data,
        method = "POST",
        localApi,
        additionalHeaders = {},
        noAuth = false,
        ctx,
    } = props;
    const headers: any = {
        ...additionalHeaders,
        'X-Token': await signJwt()
    };
    const init: any = {method, headers};
    const fullUrl = localApi
        //@ts-ignore
        ? `${absoluteUrl(ctx?.req).origin}/${url}`
        : constantConfig.API_URL + url;
    if (method === "POST") {
        headers["Content-Type"] = "application/json";
        if (data) init.body = JSON.stringify(data);
    }

    const cookies = getCookies({res: ctx?.res, req: ctx?.req});
    if (!noAuth && cookies[TOKEN_COOKIE_NAME]) {
        headers.Authorization = `Bearer ${cookies[TOKEN_COOKIE_NAME]}`;
    }
    return fetch(fullUrl, init).then(async function (response) {
        if (response.status === 200) {
            return response.json();
        } else if (response.status === 404) {
            const error: any = new Error("İçerik Bulunamadı");
            error.code = 404;
            throw error;
        } else if (response.status === 401) {
            if (cookies[REFRESH_COOKIE_NAME] && url !== endPoints.auth.refresh) {
                // refresh_token var ama auth basarisiz
                const r = await attemptRefreshAndSetCookies(cookies[REFRESH_COOKIE_NAME], ctx);
                if (!r) {
                    // refresh basarisiz, authsuz ayni istegi cik, oncesinde tokenlari zaten sildik
                    return await apiFetch({
                        ...props,
                        noAuth: true,
                    });
                }
                // refresh basarili, tokenlar setlendi, ayni istegi yeniden cik
                return await apiFetch(props);
            } else {
                throw new Error("Yetkilendirme Hatası");
            }
        } else {
            throw new Error("Hata Oluştu");
        }
    });
}

export async function attemptRefreshAndSetCookies(refreshToken: string, ctx: ApiProps['ctx']) {
    deleteCookie(TOKEN_COOKIE_NAME, {
        res: ctx?.res, req: ctx?.req,
        path: '/'
    });
    const r = await refresh(refreshToken, ctx);
    if (r.isSucceed) {
        setCookie(TOKEN_COOKIE_NAME, r.data.token, {
            res: ctx?.res, req: ctx?.req,
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
        });
        setCookie(REFRESH_COOKIE_NAME, r.data.refreshToken, {
            res: ctx?.res, req: ctx?.req,
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
        });
        return true;
    } else {
        deleteCookie(REFRESH_COOKIE_NAME, {
            res: ctx?.res, req: ctx?.req,
            path: '/'
        });
        return false;
    }
}

/**
 * Create JWT
 */
export async function signJwt() {
    const signSecret = new TextEncoder().encode(constantConfig.JWT_SECRET);
    return await new SignJWT({})
        .setProtectedHeader({alg: "HS256"})
        .setIssuedAt()
        // @ts-ignore
        .setIssuer(constantConfig.JWT_ISS)
        // @ts-ignore
        .setAudience(constantConfig.JWT_AUD)
        .setExpirationTime("1m")
        .sign(signSecret);
}
