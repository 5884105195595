import { useDispatch, useSelector } from "react-redux";
import LoginDialog from "../components/dialogs/LoginDialog";
import RegisterDialog from "../components/dialogs/RegisterDialog";
import ForgotPasswordDialog from "../components/dialogs/ForgotPasswordDialog";

function Dialogs() {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.uiReducer.modals);
  return (
    <>
      <LoginDialog
        open={modalState.login}
        onOpenChange={(v) => {
          dispatch({ type: "SET_MODAL", payload: { key: "login", value: v } });
        }}
      />
      <RegisterDialog
        open={modalState.register}
        onOpenChange={(v) => {
          dispatch({
            type: "SET_MODAL",
            payload: { key: "register", value: v },
          });
        }}
      />
      <ForgotPasswordDialog
        open={modalState.forgotPassword}
        onOpenChange={(v) => {
          dispatch({
            type: "SET_MODAL",
            payload: { key: "forgotPassword", value: v },
          });
        }}
      />
    </>
  );
}

export default Dialogs;
