import React, {memo} from "react";
import ProfileAvatar from "@/components/ProfileAvatar";
import {tmdbImageSizeChange} from "@/components/Poster";
import {useKeenSlider} from "keen-slider/react";

import type {PeopleAltType} from "@/types/contentType";
import {routes} from "@/helper/routes";
import Link from "next/link";


function PeopleListSlider({list, className = ''}: { list: PeopleAltType[], className?: string }) {
  const [peopleSliderRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      spacing: 1,
      perView: "auto",
    },
  });

  return (
    <div
      ref={peopleSliderRef}
      className={`keen-slider ${className}`}
    >
      {list.map((people) => (
        <Link
          key={people.id}
          href={{
            pathname: routes.people,
            query: {
              id: people.id
            },
          }}
        >
          <div
            className="keen-slider__slide people-list-item"
          >
            <ProfileAvatar
              absolute
              url={tmdbImageSizeChange(people.image || people.profilePath, "w154")}
              size={70}
              userName={people.name}
            />
            <p className="text-center people-list-name">
              {people.name}
            </p>
            {(people.character || people.role) && <p className="text-center people-list-role">
              {people.character || people.role}
            </p>
            }
          </div>
        </Link>
      ))}
    </div>
  )
}

export default memo(PeopleListSlider);
